import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { PktrAppMenuService } from '@prospektr/pktr-app-menu';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthorizeService } from 'src/app/_core/access-control/authorize.service';
import { TermsPolicyDirective } from 'src/app/_shared/directive/terms-policy.directive';
import { AppConfig } from 'src/app/_shared/interface/company.interface';
import { UserState } from 'src/app/_shared/state/user.state';
import { AppState } from 'src/app/app.state';
import MESSAGE from 'src/assets/resource/strings.json';
import constants from 'src/constant.json';
import { LargeModalComponent } from '../../_shared/component/large-modal/large-modal.component';

interface MenuItems {
  linkText: string;
  link: string;
  openInNewTab: boolean;
  appRoute: boolean;
  subMenuItems: Array<MenuItems>;
}

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(TermsPolicyDirective) termsAndPolicyDir;

  @Output() setNavigation = new EventEmitter<any>();

  stringRes: any = MESSAGE;
  mobileQuery: MediaQueryList;
  curActiveLink: string;
  curAppVersion: string;
  restrictedNav = [];
  constant: any = constants;
  companyDetails: any = {};
  websiteUrl: string;
  companyLogo: any;
  termsAndConditions: any = {};
  termsAndConditionsInAppDisplay: boolean;
  termsAndConditionsExternalUrl: string;
  policy: any = {};
  policyInAppDisplay: boolean;
  policyExternalUrl: string;
  additionalSideMenu: boolean = true;
  additionalSideMenuList: Array<MenuItems> = [];
  onDestroyNotifier$ = new Subject();
  companyAppConfig: AppConfig;

  private mobileQueryListener: () => void;
  userContact: any;

  constructor(
    media: MediaMatcher,
    private router: Router,
    private authorizeService: AuthorizeService,
    private appState: AppState,
    private dialog: MatDialog,
    private userState: UserState,
    public _pktrAppMenuService: PktrAppMenuService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  ngOnInit() {
    this.initStart();
  }

  initStart() {
    const currentView = this.mobileQuery.matches ? 'mobile' : 'desktop';
    this.curActiveLink = this.router.url;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((event: NavigationEnd) => {
        if (event && event.url) {
          this.curActiveLink = this.getParentPath(event.url);
        }
      });
    this.userState.userDetail$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (!this.authorizeService.canView('campaign')) {
        this.restrictedNav.push('campaign');
      } else {
        this.filterRetstrictedPage('campaign');
      }
      if (!this.authorizeService.canView('myClients')) {
        this.restrictedNav.push('myClients');
      } else {
        this.filterRetstrictedPage('myClients');
      }
      if (!this.authorizeService.canView('findAnAgent')) {
        this.restrictedNav.push('findAnAgent');
      } else {
        this.filterRetstrictedPage('findAnAgent');
      }
      if (!this.authorizeService.canView('myHome')) {
        this.restrictedNav.push('myProperty');
      } else {
        this.filterRetstrictedPage('myProperty');
      }
    });
    this.curActiveLink = this.appState.curActiveLink || (' ' + this.router.url).slice(1).replace('/', '');
    this.appState.curActiveLink = null;

    this.appState.companyInfo$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
      if (res && Object.keys(res).length) {
        const userSession = this.appState.authTokenValue?.idToken ? 'identified' : 'guest';
        this.setAdditionalSideMenu(res, userSession, currentView);
        this.companyAppConfig = res.appConfig;
      }
      this.companyDetails = res;
      this.websiteUrl = res?.websiteUrl;
      this.termsAndConditions = {
        content: res?.companyPolicy[0]?.content || null,
        title: res?.companyPolicy[0]?.title || null,
        inAppDisplay: res?.companyPolicy[0]?.inAppDisplay || false,
        externalUrl: res?.companyPolicy[0]?.externalUrl || null,
      };

      this.termsAndConditionsInAppDisplay = res?.companyPolicy[0]?.inAppDisplay || false;
      this.termsAndConditionsExternalUrl = res?.siteUrls?.terms?.link;

      this.policy = {
        content: res?.companyPolicy[1]?.content || null,
        title: res?.companyPolicy[1]?.title || null,
        inAppDisplay: res?.companyPolicy[1]?.inAppDisplay || false,
        externalUrl: res?.companyPolicy[1]?.externalUrl || null,
      };
      this.policyInAppDisplay = res?.companyPolicy[1]?.inAppDisplay || false;
      this.policyExternalUrl = res?.siteUrls?.policy?.link;
      this.companyLogo = res?.logo?.light || null;
    });
    this.userState.userDetail$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
      if (res) {
        const userSession = res ? 'identified' : 'guest';
        this.setAdditionalSideMenu(this.appState.companyInfoValue, userSession, currentView);
      }
    });

    this.userContact = this.userState.userDetails?.user_profile?.contact ? true : false;
    if (this.userState.userDetails?.source == 'CMA Report Share') {
      this.userContact = true
    }
  }

  ngAfterViewInit(): void {
    this._pktrAppMenuService.termsClicked$.subscribe((isClicked) => {
      if (isClicked) this.termsAndPolicyDir.first.triggerPolicyView('terms');
    });
    this._pktrAppMenuService.policyClicked$.subscribe((isClicked) => {
      if (isClicked) this.termsAndPolicyDir.first.triggerPolicyView('policy');
    });
  }

  filterRetstrictedPage(param) {
    this.restrictedNav = this.restrictedNav.filter((nav) => {
      return nav !== param;
    });
  }

  ngOnDestroy() {
    this.companyDetails = {};
    this.companyLogo = null;
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  navigateTo(navigationPath, isSubPath?) {
    if (navigationPath == 'myListings') {
      let redirectUrl: string = this.companyAppConfig?.myListings?.redirectUrl;
      return window.open(redirectUrl, '_self');
    }
    if (this.appState.authTokenValue?.idToken || this.authorizeService.canAct(navigationPath)) {
      if (this.router.url === '/workspace' || 'profile') {
        this.appState.curActiveLink = navigationPath;
      }
      if (isSubPath && isSubPath === 'dashboardChildPath') {
        const curPath = this.router.url === '/workspace' || 'profile' ? this.router.url : '/dashboard';
        if (curPath !== '/dashboard') {
          this.router.navigate(['/dashboard'], { state: { path: navigationPath, from: curPath } });
        } else {
          if (navigationPath === 'followed') {
            this.setNavigation.emit({ view: 'followed', type: 'groupList' });
          } else {
            this.setNavigation.emit(navigationPath);
          }
        }
      } else {
        if (this.router.url === '/dashboard' && navigationPath === 'dashboard') {
          this.setNavigation.emit(navigationPath);
        } else {
          this.router.navigate([navigationPath]);
        }
      }
    } else {
      this.router.navigate(['workspace']);
    }
  }
  viewPolicyConditions(type) {
    let content: string;
    let title: string;
    if (type == 'terms') {
      content = this.termsAndConditions.content;
      title = 'Terms and Conditions';
    } else {
      content = this.policy.content;
      title = 'Privacy Policy';
    }
    this.dialog.open(LargeModalComponent, {
      width: '60%',
      disableClose: true,
      data: {
        title,
        content,
        showPrimaryButton: true,
        primaryButtonText: 'OK',
      },
    });
  }

  getParentPath(fullPath) {
    const secondPathIndex = fullPath.indexOf('/', 1);
    const parentPath = fullPath.substring(1, secondPathIndex == -1 ? null : secondPathIndex - 1);
    return parentPath;
  }

  setAdditionalSideMenu(companyInfo, userSession, currentView) {
    if (
      companyInfo.alias &&
      companyInfo.customizationConfig &&
      companyInfo.customizationConfig.additionalSideMenu &&
      companyInfo.customizationConfig.additionalSideMenu.length &&
      companyInfo.customizationConfig.additionalSideMenu[0].activeView.includes(currentView) &&
      companyInfo.customizationConfig.additionalSideMenu[0].sessionStatus.includes(userSession) &&
      companyInfo.customizationConfig.additionalSideMenu[0].config?.active
    ) {
      this.additionalSideMenuList = companyInfo.customizationConfig.additionalSideMenu[0].config.menuItems;
      this.additionalSideMenu = true;
    } else {
      this.additionalSideMenu = false;
    }
  }
}
