<div class="hamburger-container">
  <mat-sidenav-container class="fullHeightWorkspace">
    <mat-sidenav #sidenav mode="over" position="end" disableClose>
      <div #singlePropertyDetail class="propdetails_base container newContainer text-left">
        <div class="SideToolbar">
          <div class="btn-block">
            <button
              mat-flat-button
              *ngIf="multiplePropertyList.length > 0 && !showMultipleProperty"
              class="material-icons pull-left"
              (click)="showMultipleProperty = true"
            >
              <img src="../assets/images/left-arrow.svg" width="25" />
            </button>

            <div class="float-left">
              <nav class="navbar navbar-expand-sm">
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      [ngClass]="{ active: currentDiv === 'overview' }"
                      (click)="scrollTo('overview')"
                      >Overview</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    *ngIf="
                      [
                        'Auction',
                        'Pre-Foreclosure',
                        'Hudlisting',
                        'Bankowned',
                        'Pre-Foreclosure,Auction',
                        'Bankowned,Pre-Foreclosure'
                      ].includes(propertyInfo?.saletype) ||
                      [
                        'Auction',
                        'Pre-Foreclosure',
                        'Hudlisting',
                        'Bankowned',
                        'Pre-Foreclosure,Auction',
                        'Bankowned,Pre-Foreclosure'
                      ].includes(activePropertyDetails?.saletype) ||
                      (propertyInfo?.saletype &&
                        propertyInfo?.saletype.includes(',') &&
                        propertyInfo?.saletype.includes('Auction')) ||
                      (activePropertyDetails?.saletype &&
                        activePropertyDetails?.saletype.includes(',') &&
                        activePropertyDetails?.saletype.includes('Auction'))
                    "
                  >
                    <a class="nav-link" [ngClass]="{ active: currentDiv === 'more' }" (click)="scrollTo('more')"
                      >Distress Info</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" [ngClass]="{ active: currentDiv === 'property' }" (click)="scrollTo('property')"
                      >Property Details</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      [ngClass]="{ active: currentDiv === 'value-tax-info' }"
                      (click)="scrollTo('value-tax-info')"
                      >Value & Tax Info</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" [ngClass]="{ active: currentDiv === 'schools' }" (click)="scrollTo('schools')"
                      >Schools</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" [ngClass]="{ active: currentDiv === 'trends' }" (click)="scrollTo('trends')"
                      >Trends</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
            <div class="prop_dtls_closeBtn pull-right" (click)="onClose()" aria-label="close property details">
              <img src="../assets/images/close.svg" tabindex="10" />
            </div>
            <div class="pr-4 m-actionbttn">
              <div
                class="btn-group mr-3 btn analyse_btn float-right"
                *ngIf="
                  !hideDeal &&
                  dealList &&
                  dealList.length &&
                  !showMultipleProperty &&
                  isPropertyDataExists == true &&
                  !isDealSheetOpened &&
                  companyAppConfig?.dealAnalysis?.isEnabled &&
                  !isMyHomeClicked
                "
                [ngClass]="{ movetoRight: !hideDeal && dealList && dealList.length }"
              >
                <button
                  mat-button
                  class=""
                  id="analyse_prop"
                  tabindex="10"
                  (click)="openDealAnalysisSheet(activePropertyDetails)"
                  name="Analyze property"
                >
                  Analyze Property
                </button>
                <div
                  class="btn-group"
                  *ngIf="!hideDeal && dealList && dealList.length && !isDealSheetOpened && !isMyHomeClicked"
                  ngbDropdown
                  role="group"
                  aria-label="Button group with nested dropdown"
                >
                  <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <p class="headname mb-2 px-4"><b>Saved Analysis</b></p>
                    <div class="saved-analysis">
                      <ng-container *ngFor="let deal of dealList">
                        <div
                          class="saved-list"
                          color="primary"
                          (click)="openDealAnalysisSheetWithSavedDetails(deal)"
                          ngbDropdownItem
                        >
                          {{ deal.name }}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="btn-group mr-3 btn analyse_btn float-right"
                (click)="openDealAnalysisSheet(activePropertyDetails)"
                *ngIf="
                  !dealList &&
                  !showMultipleProperty &&
                  isPropertyDataExists == true &&
                  !isDealSheetOpened &&
                  companyAppConfig?.dealAnalysis?.isEnabled &&
                  !isMyHomeClicked
                "
                [ngClass]="{ movetoRight: !hideDeal && dealList && dealList.length }"
              >
                <button mat-button class="" id="analyse_prop" tabindex="10" name="Analyze property">
                  Analyze Property
                </button>
              </div>
              <div class="btn-group mr-3 btn analyse_btn float-right" *ngIf="showAddOpportunity">
                <div class="flex_center" *ngIf="isLoadingAddOpportunity">
                  <mat-spinner [diameter]="30" class="mr-3"></mat-spinner>
                </div>
                <button
                  mat-button
                  class=""
                  id="add_opportunity"
                  tabindex="10"
                  (click)="addOpportunity()"
                  name="Add Opportunity"
                  [disabled]="isLoadingAddOpportunity"
                  *ngIf="propertyInfo.Lst_SI_ListingId || propertyInfo.ListingId || listingData.ListingId"
                >
                  Add as Opportunity
                </button>
              </div>

              <input
                type="text"
                size="0"
                style="
                  width: 1px;
                  height: 1px;
                  border: none;
                  background: none;
                  box-shadow: none;
                  cursor: default;
                  outline: none;
                "
              />
              <button
                class="px-0 mat-stroked-button btn-group mr-1 mr-md-3 btn float-right sharebtn"
                mat-button
                color="primary"
                name="share property"
                aria-label="share property"
                *ngIf="
                  !showMultipleProperty &&
                  isPropertyDataExists == true &&
                  !(companyAppConfig?.valueReport?.isEnabled || companyAppConfig?.opportunityScanner?.isEnabled)
                "
                (click)="propertyDetailsShare()"
                tabindex="10"
              >
                <mat-icon>share</mat-icon>
              </button>
              <div
                class="mr-1 mr-md-3 float-right"
                data-toggle="tooltip"
                title="Add to Watchlist"
                (click)="followProperty(activePropertyDetails)"
                *ngIf="!(companyAppConfig?.valueReport?.isEnabled || companyAppConfig?.opportunityScanner?.isEnabled)"
              >
                <button
                  class="favbtn"
                  [ngClass]="activePropertyDetails.isFavorite ? 'favoritebtn' : ''"
                  mat-flat-button
                >
                  <mat-icon [ngClass]="activePropertyDetails.isFavorite ? 'text-primary' : 'lightGreyCls'">
                    favorite</mat-icon
                  >
                </button>
              </div>
            </div>
            <!-- All browsers on iPhones automatically focus on the first visible (not hidden) input field on the page 
              Added the below input field that is present but does not appear on the screen to force the focus to the top of the page.
            -->
          </div>
        </div>
        <div class="SideContent">
          <div class="wrapperLoad" *ngIf="propertySheetFacade.getMultiplePropertyDetailsSheetLoaderStatus() | async">
            <div class="wrapper-cell">
              <div class="text">
                <div class="text-line"></div>
                <div class="text-line"></div>
                <div class="text-line"></div>
              </div>
              <div class="image"></div>
            </div>
            <div class="wrapper-cell">
              <div class="text">
                <div class="text-line"></div>
                <div class="text-line"></div>
                <div class="text-line"></div>
              </div>
              <div class="image"></div>
            </div>
            <div class="wrapper-cell">
              <div class="text">
                <div class="text-line"></div>
                <div class="text-line"></div>
                <div class="text-line"></div>
              </div>
              <div class="image"></div>
            </div>
            <div class="wrapper-cell">
              <div class="text">
                <div class="text-line"></div>
                <div class="text-line"></div>
                <div class="text-line"></div>
              </div>
              <div class="image"></div>
            </div>
            <div class="wrapper-cell">
              <div class="text">
                <div class="text-line"></div>
                <div class="text-line"></div>
                <div class="text-line"></div>
              </div>
              <div class="image"></div>
            </div>
            <div class="wrapper-cell">
              <div class="text">
                <div class="text-line"></div>
                <div class="text-line"></div>
                <div class="text-line"></div>
              </div>
              <div class="image"></div>
            </div>
            <div class="wrapper-cell">
              <div class="text">
                <div class="text-line"></div>
                <div class="text-line"></div>
                <div class="text-line"></div>
              </div>
              <div class="image"></div>
            </div>
            <div class="wrapper-cell">
              <div class="text">
                <div class="text-line"></div>
                <div class="text-line"></div>
                <div class="text-line"></div>
              </div>
              <div class="image"></div>
            </div>
            <div class="wrapper-cell">
              <div class="text">
                <div class="text-line"></div>
                <div class="text-line"></div>
                <div class="text-line"></div>
              </div>
              <div class="image"></div>
            </div>
            <div class="wrapper-cell">
              <div class="text">
                <div class="text-line"></div>
                <div class="text-line"></div>
                <div class="text-line"></div>
              </div>
              <div class="image"></div>
            </div>
          </div>
          <div
            class="fixedBottom absoluteBottom fullAbsoluteload flex_center alert text-white"
            *ngIf="propertySheetFacade.getPropertyDetailsSheetLoaderStatus() | async"
          >
            <div class="flex_center">
              <mat-spinner [diameter]="30" class="mr-3"></mat-spinner> Please wait while we load details
            </div>
          </div>

          <!-- Single Property Details -->
          <div
            class="propdetails_wrap singleDetails"
            (wheel)="onMouseWheel($event)"
            *ngIf="!showMultipleProperty && isPropertyDataExists == true"
          >
            <div class="transition is-stickyActive scroller1">
              <hr />

              <div class="clearfix"></div>
              <!-- Bird's Eye/Street View -->

              <div class="section_base scroller1">
                <div class="col-sm-12">
                  <div class="row"></div>
                </div>
              </div>
              <!-- Owner Information -->
              <div class="row" *ngIf="activePropertyDetails">
                <div class="scroller1 col-sm-12 col-md-7 col-lg-8" id="info_section">
                  <div class="clearfix"></div>

                  <div class="scroller1" *ngIf="activePropertyDetails">
                    <div class="clearfix"></div>
                    <div class="row BirdStreetView" *ngIf="!propertyInfo || !listingImages.length">
                      <mat-radio-group
                        aria-label="Select a view"
                        (change)="onViewChange($event)"
                        #radioGroup="matRadioGroup"
                      >
                        <mat-radio-button value="streetView" checked>Street View </mat-radio-button>
                        <mat-radio-button class="pl-2" value="birdsEyeView">Bird's Eye View</mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <div class="col-lg-12 px-0 listing_carousel" *ngIf="listingImages.length">
                      <div class="info_carousal">
                        <div id="demo" class="carousel slide">
                          <app-image-gallery> </app-image-gallery>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="!propertyInfo || !listingImages.length">
                      <div class="col-lg-12 px-0" [hidden]="isStreetView">
                        <app-birds-eye-view
                          [defaultPlace]="setBirdsEyeViewEvent"
                          (birdsEyeViewEvent)="setBirdsEyeView($event)"
                        >
                        </app-birds-eye-view>
                      </div>
                      <div class="col-lg-12 px-0" [hidden]="!isStreetView">
                        <app-street-view [defaultPlace]="setStreetViewEvent" (streetViewEvent)="setStreetView($event)">
                        </app-street-view>
                      </div>
                    </ng-container>
                    <div
                      class="sectioninfo col-lg-12"
                      *ngIf="propertyInfo && activePropertyDetails"
                      id="overview"
                      [ngStyle]="onScrollEvent"
                    >
                      <div class="row mt-4">
                        <div class="col-lg-8 col-md-8 col-8 pl-0" style="display: flex" *ngIf="!isAvoidListing">
                          <h2
                            class="mb-1"
                            *ngIf="
                              (propertyInfo.filterStyleClass !== 'sold' &&
                                activePropertyDetails.filterStyleClass !== 'sold') ||
                              !activePropertyDetails.filterStyleClass
                            "
                            [innerHTML]="
                              propertyInfo.Lst_ListPrice ||
                                activePropertyDetails.Lst_ListPrice ||
                                listingData.ListPrice ||
                                propertyInfo.CurrentAVMValue
                                | CurrencyFormatter
                                | IsValueExist
                            "
                          ></h2>
                          <h2
                            class="mb-1"
                            *ngIf="
                              propertyInfo?.filterStyleClass == 'sold' ||
                              activePropertyDetails?.filterStyleClass == 'sold'
                            "
                            [innerHTML]="
                              propertyInfo.CurrentAVMValue || activePropertyDetails.CurrentAVMValue
                                | CurrencyFormatter
                                | IsValueExist
                            "
                          ></h2>
                          <sub
                            *ngIf="
                              !propertyInfo.Lst_ListPrice &&
                              !activePropertyDetails.Lst_ListPrice &&
                              !listingData.ListPrice &&
                              propertyInfo.CurrentAVMValue
                            "
                            class="lh-3"
                            ><span><b>Propmix Estimated Value</b></span></sub
                          >
                          <div
                            class="difValue"
                            *ngIf="propertyInfo.Lst_OriginalListPrice - propertyInfo.Lst_ListPrice > 0"
                          >
                            <img
                              [src]="
                                propertyInfo.Lst_OriginalListPrice - propertyInfo.Lst_ListPrice > 0
                                  ? 'assets/images/arrow-up.svg'
                                  : 'assets/images/down-arrow.svg'
                              "
                              height="22px"
                            />
                            {{
                              propertyInfo.Lst_OriginalListPrice - propertyInfo.Lst_ListPrice
                                | CurrencyFormatter
                                | IsValueExist
                            }}
                          </div>
                        </div>

                        <div class="col-lg-8 col-md-8 col-8 pl-0" style="display: flex" *ngIf="isAvoidListing">
                          <h2
                            class="mb-1"
                            [innerHTML]="
                              propertyInfo.CurrentAVMValue || activePropertyDetails.CurrentAVMValue | CurrencyFormatter
                            "
                          ></h2>
                          <sub
                            *ngIf="
                              (!propertyInfo.Lst_ListPrice &&
                                !activePropertyDetails.Lst_ListPrice &&
                                !listingData.ListPrice &&
                                propertyInfo.CurrentAVMValue) ||
                              activePropertyDetails.CurrentAVMValue
                            "
                            class="lh-3"
                            ><span><b>Propmix Estimated Value</b></span></sub
                          >
                          <div
                            class="difValue"
                            *ngIf="propertyInfo.Lst_OriginalListPrice - propertyInfo.Lst_ListPrice > 0"
                          >
                            <img
                              [src]="
                                propertyInfo.Lst_OriginalListPrice - propertyInfo.Lst_ListPrice > 0
                                  ? 'assets/images/arrow-up.svg'
                                  : 'assets/images/down-arrow.svg'
                              "
                              height="22px"
                            />
                            {{
                              propertyInfo.Lst_OriginalListPrice - propertyInfo.Lst_ListPrice
                                | CurrencyFormatter
                                | IsValueExist
                            }}
                          </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-4 pr-0" *ngIf="!isAvoidListing">
                          <span
                            class="property-badge sale-type mt-2"
                            [ngClass]="propertyInfo.filterStyleClass || activePropertyDetails.filterStyleClass"
                          >
                            <span
                              *ngIf="
                                propertyInfo.filterStyleClass == 'listing' ||
                                activePropertyDetails.filterStyleClass == 'listing'
                              "
                            >
                              {{
                                listingData.MlsBoard != 'MREIS'
                                  ? propertyInfo.Lst_StandardStatus || activePropertyDetails.Lst_StandardStatus
                                  : RawMlsStatus
                              }}
                            </span>
                            <div *ngIf="RawMlsStatus != 'Deleted'">
                              <span
                                *ngIf="
                                  propertyInfo.filterStyleClass == 'sold' ||
                                  activePropertyDetails.filterStyleClass == 'sold'
                                "
                              >
                                {{
                                  listingData.MlsBoard != 'MREIS'
                                    ? propertyInfo.Lst_StandardStatus || activePropertyDetails.Lst_StandardStatus
                                    : RawMlsStatus
                                }}
                              </span>
                            </div>
                            <span
                              *ngIf="
                                (propertyInfo.filterStyleClass == 'distressed' ||
                                  activePropertyDetails.filterStyleClass == 'distressed') &&
                                !propertyInfo?.addUpdatedBadge &&
                                !activePropertyDetails?.addUpdatedBadge
                              "
                            >
                              <span class="listings">{{
                                propertyInfo?.Lst_StandardStatus || activePropertyDetails?.Lst_StandardStatus
                                  | titlecase
                              }}</span>
                              <span class="distressed">
                                {{
                                  propertyInfo?.Lst_speciallistingconditions ||
                                    activePropertyDetails?.Lst_speciallistingconditions ||
                                    propertyInfo?.Fcl_Status ||
                                    activePropertyDetails?.Fcl_Status | titlecase
                                }}</span
                              >
                            </span>
                            <span *ngIf="activePropertyDetails.addUpdatedBadge || propertyInfo?.addUpdatedBadge">
                              <span class="listings">{{
                                propertyInfo?.Lst_StandardStatus || activePropertyDetails?.Lst_StandardStatus
                                  | titlecase
                              }}</span>
                              <span
                                [ngClass]="activePropertyDetails?.filterStyleClass || propertyInfo?.filterStyleClass"
                                >{{
                                  propertyInfo?.addUpdatedBadge || activePropertyDetails?.addUpdatedBadge | titlecase
                                }}</span
                              >
                            </span>

                            <span
                              *ngIf="
                                (propertyInfo?.filterStyleClass == 'hud' ||
                                  activePropertyDetails.filterStyleClass == 'hud') &&
                                !propertyInfo?.addUpdatedBadge &&
                                !activePropertyDetails.addUpdatedBadge
                              "
                            >
                              <span class="listings">
                                {{
                                  propertyInfo?.Lst_StandardStatus || activePropertyDetails.Lst_StandardStatus
                                    | titlecase
                                }}</span
                              >
                              <span class="hud">
                                {{
                                  propertyInfo?.Lst_speciallistingconditions ||
                                    activePropertyDetails.Lst_speciallistingconditions | titlecase
                                }}
                              </span>
                            </span>

                            <span *ngIf="propertyInfo.filterStyleClass == 'nonFiltered'">
                              <span class="offMarket"> Off Market </span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-8 col-md-8 col-12 pl-0">
                          <div class="mb-0 prop-ads">
                            {{
                              activePropertyDetails?.propertyAddress || activePropertyDetails?.StreetAddress || '--'
                            }}, {{ activePropertyDetails?.City || listingData?.City || '--' }},
                            {{ activePropertyDetails?.StateOrProvince || listingData?.StateOrProvince || '--' }}
                            {{ activePropertyDetails?.PostalCode || listingData?.PostalCode }}
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12 pr-0 pl-0" *ngIf="!isAvoidListing">
                          <div *ngIf="listingData.MlsBoard != 'MREIS'">
                            <div
                              class="Listed-on"
                              *ngIf="
                                ['active', 'pending', 'active under contract'].includes(
                                  propertyInfo?.Lst_StandardStatus?.toLowerCase() ||
                                    activePropertyDetails?.Lst_StandardStatus?.toLowerCase() ||
                                    listingData?.StandardStatus?.toLowerCase()
                                )
                              "
                            >
                              Listed On:
                              <span
                                [innerHTML]="
                                  propertyInfo.Lst_ListingContractDate ||
                                    activePropertyDetails.Lst_ListingContractDate ||
                                    listingData.ListingContractDate
                                    | date: 'mediumDate'
                                    | IsValueExist
                                "
                              ></span>
                            </div>
                            <div
                              class="Listed-on"
                              *ngIf="
                                (['Auction', 'Pre-Foreclosure,Auction'].includes(propertyInfo?.saletype) ||
                                  ['Auction', 'Pre-Foreclosure,Auction'].includes(activePropertyDetails?.saletype) ||
                                  (propertyInfo?.saletype &&
                                    propertyInfo?.saletype.includes(',') &&
                                    propertyInfo?.saletype.includes('Auction')) ||
                                  (activePropertyDetails?.saletype &&
                                    activePropertyDetails?.saletype.includes(',') &&
                                    activePropertyDetails?.saletype.includes('Auction'))) &&
                                (propertyInfo.Fcl_lastauctionDate || activePropertyDetails.Fcl_lastauctionDate)
                              "
                            >
                              Auction Date:
                              <span
                                [innerHTML]="
                                  propertyInfo.Fcl_lastauctionDate || activePropertyDetails.Fcl_lastauctionDate
                                    | date: 'mediumDate'
                                    | IsValueExist
                                "
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-12 pl-0">
                          <ul class="prop_highlight pl-0 mb-0 mt-3">
                            <li [matTooltip]="'Bed'">
                              <img src="../../assets/images/bed.svg" width="24" />
                              <p class="inline-block ipad_amn_break ml-1">
                                {{
                                  propertyInfo.PropertyType === 'Land' || activePropertyDetails.PropertyType === 'Land'
                                    ? '--'
                                    : (propertyInfo.BedroomsTotal ||
                                        activePropertyDetails.BedroomsTotal ||
                                        listingData.BedroomsTotal
                                      | number: '1.0-0'
                                      | IsValueExist)
                                }}
                              </p>
                            </li>
                            <li [matTooltip]="'Bath'">
                              <img src="../../assets/images/bath.svg" width="24" />
                              <p class="inline-block ipad_amn_break ml-1">
                                {{
                                  propertyInfo.PropertyType === 'Land' || activePropertyDetails.PropertyType === 'Land'
                                    ? '--'
                                    : (propertyInfo?.BathroomsTotalInteger ||
                                        activePropertyDetails.BathroomsTotalInteger ||
                                        listingData.BathroomsTotalInteger
                                      | number: '1.0-0'
                                      | IsValueExist)
                                }}
                              </p>
                            </li>
                            <li [matTooltip]="'Living Area'">
                              <img src="../../assets/images/living_area.svg" width="24" />
                              <p class="inline-block ipad_amn_break ml-1">
                                {{
                                  propertyInfo.PropertyType === 'Land' || activePropertyDetails.PropertyType === 'Land'
                                    ? '--'
                                    : (propertyInfo?.LivingArea ||
                                        activePropertyDetails.LivingArea ||
                                        listingData.LivingArea
                                      | number: '1.0-0'
                                      | IsValueExist)
                                }}
                                <small> Sq.ft</small>
                              </p>
                            </li>
                            <li [matTooltip]="'Lot Size Square Feet'">
                              <img src="../../assets/images/land_area.svg" width="24" />
                              <p class="inline-block ipad_amn_break ml-1">
                                {{
                                  propertyInfo?.LotSizeSquareFeet ||
                                    activePropertyDetails.LotSizeSquareFeet ||
                                    listingData.LotSizeSquareFeet
                                    | number: '1.0-0'
                                    | IsValueExist
                                }}
                                <small> Sq.ft</small>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-lg-4 col-sm-6 col-6 pl-0 mb-3">
                          <h5 class="mb-1">
                            {{ propertyInfo.PropertySubType || activePropertyDetails.PropertySubType }}
                          </h5>
                          <span>Property Type</span>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-6 pl-0 mb-3" *ngIf="!isAvoidListing">
                          <h5 class="mb-1">{{ calculateDays(listingData) }}</h5>
                          <span>Days on Market</span>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-6 pl-0 mb-3">
                          <h5 class="mb-1">$196</h5>
                          <span>Price per Sq Ft</span>
                        </div>
                        <div
                          class="col-lg-4 col-sm-6 col-6 pl-0 mb-3"
                          *ngIf="
                            (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) != 'Other Land'
                          "
                        >
                          <h5 class="mb-1">
                            {{ listingData.YearBuilt || activePropertyDetails.YearBuilt | IsValueExist }}
                          </h5>
                          <span>Year Built</span>
                        </div>
                        <div
                          class="col-lg-4 col-sm-6 col-6 pl-0 mb-3"
                          *ngIf="
                            !isAvoidListing &&
                            (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) != 'Other Land'
                          "
                        >
                          <h5 class="mb-1">
                            <b *ngIf="associationFee">{{ associationFee | CurrencyFormatter | IsValueExist }}/month</b>
                            <b *ngIf="!associationFee">--</b>
                          </h5>
                          <span>Association Fee</span>
                        </div>
                        <div
                          class="col-lg-4 col-sm-6 col-6 pl-0 mb-3"
                          *ngIf="
                            (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) != 'Other Land'
                          "
                        >
                          <h5 class="mb-1">
                            {{ listingData.ParkingSpaces || activePropertyDetails.ParkingSpaces | IsValueExist }}
                          </h5>
                          <span>Parking Total</span>
                        </div>
                        <div
                          class="col-lg-4 col-sm-6 col-6 pl-0 mb-3"
                          *ngIf="
                            (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) != 'Other Land'
                          "
                        >
                          <h5 class="mb-1">
                            {{
                              listingData.GarageYN
                                ? listingData.GarageYN == 'Y'
                                  ? 'Yes'
                                  : 'No'
                                : activePropertyDetails.GarageArea
                                ? 'Yes'
                                : 'No'
                            }}
                          </h5>
                          <span>Garage Spaces</span>
                        </div>
                        <div
                          class="col-lg-4 col-sm-6 col-6 pl-0 mb-3"
                          *ngIf="
                            (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) != 'Other Land'
                          "
                        >
                          <h5 class="mb-1">
                            {{
                              listingData.ArchitecturalStyle || activePropertyDetails.ArchitecturalStyle | IsValueExist
                            }}
                          </h5>
                          <span>Architectural Style</span>
                        </div>
                        <div
                          class="col-lg-4 col-sm-6 col-6 pl-0 mb-3"
                          *ngIf="
                            (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) != 'Other Land'
                          "
                        >
                          <h5 class="mb-1">
                            {{ listingData.PoolType || activePropertyDetails.PoolType | IsValueExist }}
                          </h5>
                          <span>Pool</span>
                        </div>
                        <div
                          class="col-lg-4 col-sm-6 col-6 pl-0 mb-3"
                          *ngIf="
                            (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) != 'Other Land'
                          "
                        >
                          <h5 class="mb-1">
                            {{
                              propertyInfo?.StoriesTotal ||
                                activePropertyDetails.StoriesTotal ||
                                listingData.StoriesTotal ||
                                '--'
                            }}
                          </h5>
                          <span>Stories</span>
                        </div>
                      </div>
                      <div class="row mt-3 list-data" *ngIf="!isAvoidListing">
                        <div class="mb-2">
                          <span>
                            {{ showMore ? listingData.PublicRemarks : (listingData.PublicRemarks | slice: 0:150) }}
                            <a class="linkCursor" *ngIf="!showMore" (click)="onShow()">Show More</a>
                            <a class="linkCursor" *ngIf="showMore" (click)="onShow()">Show Less</a>
                          </span>
                        </div>

                        <div
                          class="w-100 my-2"
                          *ngIf="
                            !companyAppConfig?.propertyDetails?.hideListingAgentContactInfo &&
                            listingData.MlsBoard != 'MREIS'
                          "
                        >
                          <div>Listed By:</div>
                          <div
                            *ngIf="
                              !listingData.ListAgentFullName &&
                                !listingData.ListAgentPhone &&
                                !listingData.ListAgentEmail &&
                                !listingData.ListAgentOfficeName;
                              else noListingAgentInfo
                            "
                          >
                            --
                          </div>
                          <ng-template #noListingAgentInfo>
                            <div style="word-wrap: break-word; font-weight: 400">
                              {{ listingData.ListAgentFullName | IsValueExist }},
                              {{ listingData.ListAgentPreferredPhone | IsValueExist }},
                              {{ listingData.ListAgentEmail | IsValueExist }},
                              {{ listingData.ListOfficeName | IsValueExist }}
                            </div>
                          </ng-template>
                        </div>
                        <div
                          class="w-100 my-2"
                          *ngIf="
                            !companyAppConfig?.propertyDetails?.hideListingAgentContactInfo &&
                            listingData.MlsBoard == 'MREIS'
                          "
                        >
                          <div>Listed By:</div>
                          <div
                            *ngIf="
                              !listingData.ListAgentFullName &&
                                !listingData.ListAgentPhone &&
                                !listingData.ListAgentEmail &&
                                !listingData.ListAgentOfficeName;
                              else noListingAgentInfo
                            "
                          >
                            --
                          </div>
                          <ng-template #noListingAgentInfo>
                            <div style="word-wrap: break-word; font-weight: 400">
                              {{ listingData.ListOfficeName | IsValueExist }}<br />
                              Contact: {{ listedByContact }}
                            </div>
                          </ng-template>
                        </div>
                        <div class="mb-2 mt-2">
                          <b>Last Checked:</b> In the last 15 minutes | <b>Last Updated:</b>
                          {{ listingData.ModificationTimestamp | date: 'yyyy-MM-dd' }} | <b>Source:</b>
                          {{ listingData.OriginatingSystemName }}
                          <img src="{{ mlsLogo }}" height="40" class="ml-2" />
                          <br /><span>MLS#: {{ listingData.MlsListingNumber | IsValueExist }}</span>
                        </div>

                        <div
                          class="mb-2 copyright"
                          *ngIf="companyAppConfig?.propertyDetails?.hideListingAgentContactInfo"
                        >
                          Courtesy of: {{ listingData.ListOfficeName | IsValueExist }}
                        </div>

                        <div class="mb-2 copyright">Disclaimer: {{ mlsDisclaimer }}</div>
                      </div>
                      <div class="row">
                        <div class="col-12 m-block px-0 pt-3">
                          <div class="propdetails_rightsec" *ngIf="canViewAgentContactInfo">
                            <mat-accordion multi="true">
                              <mat-expansion-panel
                                class="contact-agent mb-4"
                                [expanded]="true"
                                *ngIf="
                                  ['active', 'pending', 'active under contract'].includes(
                                    propertyInfo?.Lst_StandardStatus?.toLowerCase() ||
                                      activePropertyDetails?.Lst_StandardStatus?.toLowerCase() ||
                                      listingData?.StandardStatus?.toLowerCase()
                                  )
                                "
                              >
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    <h4 class="mb-0">Contact Agent</h4>
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <app-agent-contact> </app-agent-contact>
                              </mat-expansion-panel>
                              <mat-expansion-panel
                                [expanded]="true"
                                *ngIf="
                                  ['active', 'pending', 'active under contract'].includes(
                                    propertyInfo?.Lst_StandardStatus?.toLowerCase() ||
                                      activePropertyDetails?.Lst_StandardStatus?.toLowerCase() ||
                                      listingData?.StandardStatus?.toLowerCase()
                                  )
                                "
                              >
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    <h4 class="mb-0">Payment Calculator</h4>
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="bold ml-3 mb-0">Calculator and Data provided by PropMix.io LLC</p>
                                <app-payment-calculator class="pt-4 pt-md-3 pt-lg-4"> </app-payment-calculator>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </div>
                          <br />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12 mt-4" #mapElement style="width: 100%; height: 300px"></div>
                      </div>
                    </div>

                    <div class="section_base ownerNameWrap" *ngIf="appState.authTokenValue?.idToken">
                      <div class="row ml-0 mr-0">
                        <div class="col-lg-12">
                          <div class="leftSide">
                            <label>Owner Name :</label>
                            <h6 class="truncate">
                              {{ activePropertyDetails.Owner1FullName || propertyInfo?.Owner1FullName | IsValueExist }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="section_base">
                      <div
                        *ngIf="
                          ['Auction', 'Hudlisting', 'Pre-Foreclosure', 'Bankowned', 'Pre-Foreclosure,Auction'].includes(
                            propertyInfo?.saletype
                          ) ||
                          ['Auction', 'Hudlisting', 'Pre-Foreclosure', 'Bankowned', 'Pre-Foreclosure,Auction'].includes(
                            activePropertyDetails?.saletype
                          ) ||
                          (propertyInfo?.saletype &&
                            propertyInfo?.saletype.includes(',') &&
                            propertyInfo?.saletype.includes('Auction')) ||
                          (activePropertyDetails?.saletype &&
                            activePropertyDetails?.saletype.includes(',') &&
                            activePropertyDetails?.saletype.includes('Auction'))
                        "
                      >
                        <ng-container *ngTemplateOutlet="distressedInfoContent"></ng-container>
                      </div>

                      <!-- Property Information -->

                      <div class="sectioninfo mt-4" id="property" #property [ngStyle]="onScrollEvent">
                        <h4 class="headTitle">
                          <img src="../../../assets/images/propertyInfo.svg" class="mr-2" width="18" /> Property
                          Information
                        </h4>

                        <!-- Location Information -->

                        <div class="propertCharacter">
                          <div class="row">
                            <div class="col-lg-12">
                              <h6 class="my-3 headWrap">Location Information</h6>
                              <ul class="pl-0 w-100 prop_char">
                                <li>
                                  <div>Parcel Number:</div>
                                  <div>
                                    {{ activePropertyDetails.ParcelNumber || listingData.ParcelNumber | IsValueExist }}
                                  </div>
                                </li>
                                <li>
                                  <div>County:</div>
                                  <div>{{ activeCountyValue | IsValueExist }}</div>
                                </li>
                                <li>
                                  <div>Association Name:</div>
                                  <div>{{ listingData.AssociationName | IsValueExist }}</div>
                                </li>
                                <li>
                                  <div>Subdivision Name:</div>
                                  <div>
                                    {{
                                      activePropertyDetails.LegalSubdivisionName || listingData.SubdivisionName
                                        | IsValueExist
                                    }}
                                  </div>
                                </li>
                                <li>
                                  <div>Census Track Id:</div>
                                  <div>{{ activePropertyDetails.CensusTractId | IsValueExist }}</div>
                                </li>
                                <li>
                                  <div>Municipality:</div>
                                  <div>{{ activePropertyDetails.Municipality | IsValueExist }}</div>
                                </li>
                                <li>
                                  <div>Lot:</div>
                                  <div>{{ activePropertyDetails.TaxLotList | IsValueExist }}</div>
                                </li>
                                <li>
                                  <div>Block:</div>
                                  <div>{{ activePropertyDetails.TaxBlock | IsValueExist }}</div>
                                </li>
                                <li>
                                  <div>Section:</div>
                                  <div>{{ activePropertyDetails.TaxSection | IsValueExist }}</div>
                                </li>
                                <li>
                                  <div>Zoning:</div>
                                  <div>{{ activePropertyDetails.Zoning | IsValueExist }}</div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <hr />

                        <!-- Interior Features -->

                        <div
                          class="propertCharacter"
                          *ngIf="
                            (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) != 'Other Land'
                          "
                        >
                          <div class="row">
                            <div class="col-lg-12">
                              <h6 class="my-3 headWrap">Interior Features</h6>
                              <div class="row">
                                <ul class="pl-0 w-50" style="padding-right: 10px">
                                  <li class="Interior-ul">
                                    <div class="property">Rooms:</div>
                                    <ul>
                                      <li>
                                        Bedrooms:
                                        <span>
                                          {{
                                            propertyInfo?.PropertyType === 'Land' ||
                                            activePropertyDetails?.PropertyType === 'Land'
                                              ? '--'
                                              : propertyInfo?.BedroomsTotal ||
                                                activePropertyDetails.BedroomsTotal ||
                                                listingData.BedroomsTotal ||
                                                '--'
                                          }}
                                        </span>
                                      </li>
                                      <li>
                                        Full Bathrooms:
                                        <span>
                                          {{
                                            propertyInfo?.PropertyType === 'Land' ||
                                            activePropertyDetails?.PropertyType === 'Land'
                                              ? '--'
                                              : (propertyInfo?.BathroomsFull ||
                                                  activePropertyDetails.BathroomsFull ||
                                                  listingData.BathroomsFull | IsValueExist)
                                          }}
                                        </span>
                                      </li>
                                      <li>
                                        Half Bathrooms:
                                        <span>
                                          {{
                                            propertyInfo?.PropertyType === 'Land' ||
                                            activePropertyDetails?.PropertyType === 'Land'
                                              ? '--'
                                              : (propertyInfo?.BathroomsPartial ||
                                                  activePropertyDetails.BathroomsPartial ||
                                                  listingData.BathroomsHalf | IsValueExist)
                                          }}
                                        </span>
                                      </li>
                                      <li>
                                        Total Rooms:
                                        <span>
                                          {{
                                            propertyInfo?.PropertyType === 'Land' ||
                                            activePropertyDetails?.PropertyType === 'Land'
                                              ? '--'
                                              : activePropertyDetails.RoomsTotal || listingData.RoomsTotal || '--'
                                          }}
                                        </span>
                                      </li>
                                    </ul>
                                  </li>
                                  <li class="Interior-ul">
                                    <div class="property">Fireplaces:</div>
                                    <ul>
                                      <li *ngIf="activePropertyDetails.FireplacesTotal || listingData.FireplacesTotal">
                                        Number of Fireplaces:
                                        <span>{{
                                          activePropertyDetails.FireplacesTotal || listingData.FireplacesTotal || '--'
                                        }}</span>
                                      </li>
                                      <li
                                        *ngIf="!listingData.FireplacesTotal && !activePropertyDetails.FireplacesTotal"
                                      >
                                        Fireplaces:
                                        <span>{{ listingData.FireplaceYN === 'Y' ? 'Yes' : 'No' }}</span>
                                      </li>
                                      <li>
                                        Fireplace Features:
                                        <span>{{ listingData.FireplaceFeatures | IsValueExist }}</span>
                                      </li>
                                    </ul>
                                  </li>
                                  <li class="Interior-ul">
                                    <div class="property">Area:</div>
                                    <ul>
                                      <li>
                                        Living Area:
                                        <span
                                          *ngIf="
                                            propertyInfo?.LivingArea ||
                                            activePropertyDetails.LivingArea ||
                                            listingData.LivingArea
                                          "
                                        >
                                          {{
                                            propertyInfo?.PropertyType === 'Land' ||
                                            activePropertyDetails?.PropertyType === 'Land'
                                              ? '--'
                                              : (propertyInfo?.LivingArea ||
                                                  activePropertyDetails.LivingArea ||
                                                  listingData.LivingArea) + ' Sq.ft'
                                          }}
                                        </span>
                                        <span
                                          *ngIf="
                                            !listingData.LivingArea &&
                                            !activePropertyDetails.LivingArea &&
                                            !propertyInfo?.LivingArea
                                          "
                                        >
                                          --
                                        </span>
                                      </li>
                                      <li>
                                        Building Area:
                                        <span *ngIf="activePropertyDetails.BuildingAreaTotal">
                                          {{
                                            propertyInfo?.PropertyType === 'Land' ||
                                            activePropertyDetails?.PropertyType === 'Land'
                                              ? '--'
                                              : activePropertyDetails.BuildingAreaTotal + ' Sq.ft'
                                          }}
                                        </span>
                                        <span *ngIf="!activePropertyDetails.BuildingAreaTotal">--</span>
                                      </li>
                                      <li>
                                        Garage Area:
                                        <span *ngIf="activePropertyDetails.GarageArea">
                                          {{
                                            propertyInfo?.PropertyType === 'Land' ||
                                            activePropertyDetails?.PropertyType === 'Land'
                                              ? '--'
                                              : activePropertyDetails.GarageArea + ' Sq.ft'
                                          }}
                                        </span>
                                        <span *ngIf="!activePropertyDetails.GarageArea">--</span>
                                      </li>
                                    </ul>
                                  </li>
                                  <li class="Interior-ul">
                                    <div class="property">Basement:</div>
                                    <ul>
                                      <li>
                                        <span *ngIf="listingData.Basement"> {{ listingData.Basement }}</span>
                                        <span *ngIf="listingData.BasementEntry"> {{ listingData.BasementEntry }}</span>
                                        <span *ngIf="listingData.BasementType"> {{ listingData.BasementType }}</span>
                                        <span
                                          *ngIf="
                                            !!listingData.Basement ||
                                            !!listingData.BasementEntry ||
                                            !!listingData.BasementType
                                          "
                                        >
                                          --
                                        </span>
                                      </li>
                                      <li>
                                        Total Area:
                                        <span
                                          *ngIf="
                                            activePropertyDetails.BelowGradeTotalArea || listingData.BelowGradeTotalArea
                                          "
                                        >
                                          {{
                                            activePropertyDetails.BelowGradeTotalArea ||
                                              listingData.BelowGradeTotalArea +
                                                '
                                    Sq.ft'
                                          }}
                                        </span>
                                        <span
                                          *ngIf="
                                            !activePropertyDetails.BelowGradeTotalArea &&
                                            !listingData.BelowGradeTotalArea
                                          "
                                        >
                                          --
                                        </span>
                                      </li>
                                      <li>
                                        Finished Area:
                                        <span
                                          *ngIf="
                                            listingData.BelowGradeFinishedAreaUnits &&
                                            !activePropertyDetails.BelowGradeFinishedArea
                                          "
                                        >
                                          {{ listingData.BelowGradeFinishedArea }}
                                          {{
                                            listingData.BelowGradeFinishedAreaUnits
                                              ? ' ' + listingData.BelowGradeFinishedAreaUnits
                                              : ' Sq.ft'
                                          }}
                                        </span>
                                        <span
                                          *ngIf="
                                            !listingData.BelowGradeFinishedAreaUnits &&
                                            activePropertyDetails.BelowGradeFinishedArea
                                          "
                                        >
                                          {{ activePropertyDetails.BelowGradeFinishedArea + ' Sq.ft' }}
                                        </span>
                                        <span
                                          *ngIf="
                                            !activePropertyDetails.BelowGradeFinishedArea &&
                                            !listingData.BelowGradeFinishedArea
                                          "
                                        >
                                          --
                                        </span>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                                <ul class="pl-0 w-50 other-details" style="padding-right: 10px">
                                  <li *ngIf="!isAvoidListing">
                                    <div>Appliances:</div>
                                    <div>{{ listingData.Appliances | IsValueExist }}</div>
                                  </li>
                                  <li *ngIf="!isAvoidListing">
                                    <div>Interior Features:</div>
                                    <div>{{ listingData.InteriorFeatures | IsValueExist }}</div>
                                  </li>
                                  <li>
                                    <div>Heating:</div>
                                    <div>
                                      {{
                                        propertyInfo?.PropertyType === 'Land' ||
                                        activePropertyDetails?.PropertyType === 'Land'
                                          ? '--'
                                          : listingData.Heating && listingData.HeatingType
                                          ? listingData.Heating + ',' + listingData.HeatingType
                                          : listingData.Heating ||
                                            listingData.HeatingType ||
                                            activePropertyDetails.Heating ||
                                            '--'
                                      }}
                                    </div>
                                  </li>
                                  <li>
                                    <div>Cooling:</div>
                                    <div>
                                      {{
                                        propertyInfo?.PropertyType === 'Land' ||
                                        activePropertyDetails?.PropertyType === 'Land'
                                          ? '--'
                                          : listingData.Cooling && listingData.CoolingType
                                          ? listingData.Cooling + ',' + listingData.CoolingType
                                          : listingData.Cooling ||
                                            listingData.CoolingType ||
                                            activePropertyDetails.Cooling ||
                                            '--'
                                      }}
                                    </div>
                                  </li>
                                  <li>
                                    <div>Flooring:</div>
                                    <div>{{ activePropertyDetails.Flooring || listingData.Flooring || '--' }}</div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />

                        <!-- Exterior Features -->

                        <div
                          class="propertCharacter"
                          *ngIf="
                            (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) != 'Other Land'
                          "
                        >
                          <div class="row">
                            <div class="col-lg-12">
                              <h6 class="my-3 headWrap">Exterior Features</h6>
                              <ul class="pl-0 w-100 prop_char" style="padding-right: 10px">
                                <li>
                                  <div>Roof Description:</div>
                                  <div>
                                    {{
                                      listingData.Roof ||
                                        (activePropertyDetails.RoofType && activePropertyDetails.RoofCoverType
                                          ? activePropertyDetails.RoofType + ',' + activePropertyDetails.RoofCoverType
                                          : activePropertyDetails.RoofType ||
                                            activePropertyDetails.RoofCoverType ||
                                            '--')
                                    }}
                                  </div>
                                </li>
                                <li>
                                  <div>Patio & Porch Features:</div>
                                  <div>{{ listingData.PatioAndPorchFeatures | IsValueExist }}</div>
                                </li>
                                <li>
                                  <div>Pool Features:</div>
                                  <div>
                                    {{
                                      listingData.PoolFeatures ||
                                        listingData.PoolType ||
                                        activePropertyDetails.PoolType ||
                                        '--'
                                    }}
                                  </div>
                                </li>
                                <li>
                                  <div>Pool Private YN:</div>
                                  <div>{{ listingData.PoolPrivateYN | IsValueExist }}</div>
                                </li>
                                <li *ngIf="!isAvoidListing">
                                  <div>Exterior Features:</div>
                                  <div>{{ listingData.ExteriorFeatures | IsValueExist }}</div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <hr />

                        <!-- Property/Lot Details -->

                        <div class="propertCharacter">
                          <div class="row">
                            <div class="col-lg-12">
                              <h6 class="my-3 headWrap">Property/Lot Details</h6>
                              <ul class="pl-0 w-100 prop_char" style="padding-right: 10px">
                                <li>
                                  <div>Property Type:</div>
                                  <div>
                                    {{
                                      propertyInfo?.PropertySubType ||
                                        activePropertyDetails.PropertySubType ||
                                        listingData.PropertySubType ||
                                        '--'
                                    }}
                                  </div>
                                </li>
                                <li>
                                  <div>Subdivision Name:</div>
                                  <div>
                                    {{
                                      listingData.SubdivisionName || activePropertyDetails.LegalSubdivisionName || '--'
                                    }}
                                  </div>
                                </li>
                                <li
                                  *ngIf="
                                    (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) !=
                                    'Other Land'
                                  "
                                >
                                  <div>Association YN:</div>
                                  <div>{{ listingData.AssociationYN === 'Y' ? 'Yes' : 'No' }}</div>
                                </li>
                                <li
                                  *ngIf="
                                    (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) !=
                                    'Other Land'
                                  "
                                >
                                  <div>Association Name:</div>
                                  <div>{{ listingData.AssociationName | IsValueExist }}</div>
                                </li>
                                <li
                                  *ngIf="
                                    !isAvoidListing &&
                                    (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) !=
                                      'Other Land'
                                  "
                                >
                                  <div>Association Fee:</div>
                                  <div>{{ listingData.AssociationFee | CurrencyFormatter | IsValueExist }}</div>
                                </li>
                                <li
                                  *ngIf="
                                    (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) !=
                                    'Other Land'
                                  "
                                >
                                  <div>Association Fee Frequency:</div>
                                  <div>
                                    {{ listingData.AssociationFeeFrequency | CurrencyFormatter | IsValueExist }}
                                  </div>
                                </li>
                                <li
                                  *ngIf="
                                    (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) !=
                                    'Other Land'
                                  "
                                >
                                  <div>Association Fee Includes:</div>
                                  <div>{{ listingData.AssociationFeeIncludes | IsValueExist }}</div>
                                </li>
                                <li
                                  *ngIf="
                                    (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) !=
                                    'Other Land'
                                  "
                                >
                                  <div>Architectural Style:</div>
                                  <div>
                                    {{
                                      listingData.ArchitecturalStyle || activePropertyDetails.ArchitecturalStyle || '--'
                                    }}
                                  </div>
                                </li>
                                <li
                                  *ngIf="
                                    (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) !=
                                    'Other Land'
                                  "
                                >
                                  <div>Garage YN:</div>
                                  <div>
                                    {{
                                      listingData.GarageYN
                                        ? listingData.GarageYN == 'Y'
                                          ? 'Yes'
                                          : 'No'
                                        : activePropertyDetails.GarageArea
                                        ? 'Yes'
                                        : 'No'
                                    }}
                                  </div>
                                </li>
                                <li
                                  *ngIf="
                                    (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) !=
                                    'Other Land'
                                  "
                                >
                                  <div>Attached Garage YN:</div>
                                  <div>{{ listingData.AttachedGarageYN === 'Y' ? 'Yes' : 'No' }}</div>
                                </li>
                                <li
                                  *ngIf="
                                    (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) !=
                                    'Other Land'
                                  "
                                >
                                  <div>Garage Spaces:</div>
                                  <div>{{ listingData.GarageSpaces | IsValueExist }}</div>
                                </li>
                                <li
                                  *ngIf="
                                    (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) !=
                                    'Other Land'
                                  "
                                >
                                  <div>Parking Spaces:</div>
                                  <div>
                                    {{ propertyInfo?.ParkingSpaces || activePropertyDetails.ParkingSpaces || '--' }}
                                  </div>
                                </li>
                                <li
                                  *ngIf="
                                    (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) !=
                                    'Other Land'
                                  "
                                >
                                  <div>Year Built:</div>
                                  <div>{{ listingData.YearBuilt || activePropertyDetails.YearBuilt || '--' }}</div>
                                </li>
                                <li
                                  *ngIf="
                                    (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) !=
                                    'Other Land'
                                  "
                                >
                                  <div>Year Built Effective:</div>
                                  <div>{{ activePropertyDetails.YearBuiltEffective | IsValueExist }}</div>
                                </li>
                                <li>
                                  <div>Lot Size(Sq Ft):</div>
                                  <div
                                    *ngIf="
                                      listingData.LotSizeSquareFeet ||
                                      activePropertyDetails.LotSizeSquareFeet ||
                                      listingData.LotSizeSquareFeet
                                    "
                                  >
                                    {{
                                      (activePropertyDetails.LotSizeSquareFeet ||
                                        listingData.LotSizeSquareFeet ||
                                        propertyInfo?.LotSizeSquareFeet) + ' Sq.ft'
                                    }}
                                  </div>
                                  <div
                                    *ngIf="
                                      !listingData.LotSizeSquareFeet &&
                                      !activePropertyDetails.LotSizeSquareFeet &&
                                      !listingData.LotSizeSquareFeet
                                    "
                                  >
                                    --
                                  </div>
                                </li>
                                <li>
                                  <div>Lot Size(Acres):</div>
                                  <div>
                                    {{ activePropertyDetails.LotSizeAcres || listingData.LotSizeAcres || '--' }}
                                  </div>
                                </li>
                                <li>
                                  <div>Lot Size Dimensions:</div>
                                  <div>
                                    {{
                                      activePropertyDetails.LotSizeDimensions || listingData.LotSizeDimensions || '--'
                                    }}
                                  </div>
                                </li>
                                <li>
                                  <div>Senior Community YN:</div>
                                  <div>{{ listingData.SeniorCommunityYN === 'Y' ? 'Yes' : 'No' }}</div>
                                </li>
                                <li>
                                  <div>Assessors Map Reference:</div>
                                  <div>{{ activePropertyDetails.AssessorsMapReference | IsValueExist }}</div>
                                </li>
                                <li>
                                  <div>Lot Features:</div>
                                  <div>{{ listingData.LotFeatures | IsValueExist }}</div>
                                </li>
                                <li class="w-100" *ngIf="RawMlsStatus == 'Closed'">
                                  <div>Bought with:</div>
                                  <div
                                    *ngIf="
                                      !listingData.BuyerOfficeName && !listingData.BuyerOfficeEmail;
                                      else noListingAgentInfo
                                    "
                                  >
                                    --
                                  </div>
                                  <ng-template #noListingAgentInfo>
                                    <div style="word-wrap: break-word; font-weight: 400">
                                      {{ listingData.BuyerOfficeName | IsValueExist }}
                                      {{
                                        listingData.BuyerOfficeEmail
                                          ? ', Contact: ' + (listingData.BuyerOfficeEmail | IsValueExist)
                                          : ''
                                      }}
                                    </div>
                                  </ng-template>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <hr />

                        <!-- Property Transaction History -->

                        <div class="propertCharacter">
                          <div class="row">
                            <div class="col-lg-12">
                              <mat-accordion class="subSection">
                                <mat-expansion-panel [expanded]="true">
                                  <mat-expansion-panel-header>
                                    <mat-panel-title>
                                      <h6 class="my-3 headWrap">Property Transaction History</h6>
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <div>
                                    <div
                                      *ngIf="
                                        activeTransactionHistory && activeTransactionHistory.length > 0;
                                        else noTransactionHistory
                                      "
                                    >
                                      <div class="timeline-container">
                                        <div class="timeline-item" *ngFor="let event of activeTransactionHistory">
                                          <div class="timeline-p">
                                            <div class="timeline-item-content">
                                              <h4>{{ event.TransactionContractDate | date: 'mediumDate' }}</h4>
                                              <div class="transaction-details">
                                                <div><b>Transaction Type:</b> {{ event.TransactionType }}</div>
                                                <div>
                                                  <b>Close Price:</b>
                                                  {{ event.ClosePrice | currency: 'USD':'symbol':'1.2-2' }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <ng-template #noTransactionHistory>
                                      <div class="flex_center notFound text-center mt-3 mb-3">
                                        <div>
                                          <h4>No property transaction history available</h4>
                                        </div>
                                      </div>
                                    </ng-template>
                                    <div class="text-center mt-2" style="font-style: italic; color: gray">
                                      Transaction Data source:
                                      {{
                                        activeCountyValue && activeCountyValue != '--' ? activeCountyValue : 'County'
                                      }}
                                      Assessor
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div></div>

                    <!-- Value and Tax Information -->

                    <div class="sectioninfo mt-4" id="value-tax-info" [ngStyle]="onScrollEvent">
                      <h4 class="headTitle">
                        <img src="../../../assets/images/taxInfo.svg" width="18" class="mr-2" /> Value and Tax
                        Information
                      </h4>

                      <!-- Tax Information -->

                      <div class="row">
                        <div class="col-lg-12">
                          <h6 class="my-3 headWrap">Tax Information</h6>
                          <ul class="pl-0 w-100 prop_char">
                            <li>
                              <div>Tax Year:</div>
                              <div>{{ activePropertyDetails.TaxYear | IsValueExist }}</div>
                            </li>
                            <li>
                              <div>Annual Taxes:</div>
                              <div>
                                {{ activePropertyDetails.TaxAnnualAmount | CurrencyFormatter | IsValueExist }}
                              </div>
                            </li>
                            <li>
                              <div>Tax Exemption Status:</div>
                              <div>--</div>
                            </li>
                            <li>
                              <div>Tax District:</div>
                              <div>{{ activePropertyDetails.TaxDistrict | IsValueExist }}</div>
                            </li>
                          </ul>
                          <ul class="pl-0 w-100 prop_char">
                            <li>
                              <div>Assessed Year:</div>
                              <div>{{ activePropertyDetails.AssessedYear | IsValueExist }}</div>
                            </li>
                            <li>
                              <div>Assessed Value:</div>
                              <div>
                                {{ activePropertyDetails.AssessedValue | CurrencyFormatter | IsValueExist }}
                              </div>
                            </li>
                            <li>
                              <div>Assessed Improvement Value:</div>
                              <div>
                                {{ activePropertyDetails.AssessedImprovementValue | CurrencyFormatter | IsValueExist }}
                              </div>
                            </li>
                            <li>
                              <div>Assessed Land Value:</div>
                              <div>
                                {{ activePropertyDetails.AssessedLandValue | CurrencyFormatter | IsValueExist }}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr />

                      <!-- Ownership Information -->

                      <div class="row">
                        <div class="col-lg-12">
                          <h6 class="my-3 headWrap">Ownership Information</h6>
                          <ul class="pl-0 w-100 prop_char">
                            <li>
                              <div>Owner Occupied:</div>
                              <div>{{ activePropertyDetails.OwnerOccupied | IsValueExist | YesOrNoConverter }}</div>
                            </li>
                            <li>
                              <div>Owned by Corporation:</div>
                              <div>
                                {{
                                  (activePropertyDetails.Owner1IsCorporation
                                    ? activePropertyDetails.Owner1IsCorporation
                                    : activePropertyDetails.Owner2IsCorporation
                                  )
                                    | IsValueExist
                                    | YesOrNoConverter
                                }}
                              </div>
                            </li>
                            <li>
                              <div>Owned Since:</div>
                              <div>
                                {{ activePropertyDetails.LastSaleContractDate | date: 'mediumDate' | IsValueExist }}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr />

                      <!-- Valuation -->

                      <div class="row">
                        <div class="col-lg-12">
                          <h6 class="my-3 headWrap">Valuation</h6>
                          <ul class="pl-0 w-100 prop_char">
                            <li
                              *ngIf="
                                activeAVMHistory &&
                                activeAVMHistory.length > 0 &&
                                listingData.MlsBoard != 'MREIS' &&
                                companyName != 'valuetest'
                              "
                            >
                              <div>Current Value:</div>
                              <div>{{ activeAVMHistory[0].AVMValue | CurrencyFormatter | IsValueExist }}</div>
                            </li>

                            <li
                              *ngIf="
                                (propertyInfo?.PropertySubType || activePropertyDetails?.PropertySubType) !=
                                'Other Land'
                              "
                            >
                              <div>Rent Value</div>
                              <div
                                [innerHTML]="
                                  propertyInfo?.RentValue || activePropertyDetails?.RentValue
                                    | CurrencyFormatter
                                    | IsValueExist
                                "
                              ></div>
                            </li>

                            <li *ngIf="activeAVMHistory && activeAVMHistory.length > 0 && companyName != 'valuetest'">
                              <div>Value Range:</div>
                              <div>
                                {{ activeAVMHistory[0].AVMLowValue | CurrencyFormatter | IsValueExist }} -
                                {{ activeAVMHistory[0].AVMHighValue | CurrencyFormatter | IsValueExist }}
                              </div>
                            </li>

                            <li *ngIf="propertyInfo?.CapRate || activePropertyDetails.CapRate">
                              <div>Cap Rate</div>
                              <div [innerHTML]="(propertyInfo?.CapRate || activePropertyDetails.CapRate) + '%'"></div>
                            </li>
                            <li *ngIf="propertyInfo?.rebateAmount">
                              <div>Cash Back</div>
                              <div class="rebate">${{ propertyInfo?.rebateAmount | number }}</div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <!-- AVM History -->

                      <div class="row">
                        <div class="col-lg-12">
                          <h6 class="my-3">Property Value</h6>
                          <div>
                            <div
                              id="avmChart"
                              *ngIf="AVMHistoryDetails && AVMHistoryDetails.length > 0; else noAVMHistory"
                            >
                              <app-area-chart [avmData]="AVMHistoryDetails"></app-area-chart>
                            </div>

                            <ng-template #noAVMHistory>
                              <div class="flex_center notFound text-center mt-3 mb-3">
                                <div>
                                  <h4>No property value history available</h4>
                                </div>
                              </div>
                            </ng-template>
                            <div class="text-center mt-2" style="font-style: italic; color: gray">
                              Valuation data is provided by PropMix.io, LLC
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <!-- Equity and Mortgage Information -->

                    <div class="sectioninfo mt-4" *ngIf="authorizeService.canView('mortgages')">
                      <h4 class="headTitle">
                        <img src="../../../assets/images/mortgageInfo.svg" class="mr-2" width="22" />Equity and Mortgage
                        Information
                      </h4>
                      <div class="row">
                        <div class="col-lg-12">
                          <h6 class="my-3 headWrap">Net Equity</h6>
                          <ul class="pl-0 w-100 prop_char">
                            <li *ngIf="activeAVMHistory && activeAVMHistory.length > 0">
                              <div>Current Value:</div>
                              <div>{{ activeAVMHistory[0].AVMValue | CurrencyFormatter | IsValueExist }}</div>
                            </li>
                            <li>
                              <div>Est. Total Loan Balance:</div>
                              <div>{{ totalLoanBalanceAtMonthEnd | CurrencyFormatter | IsValueExist }}</div>
                            </li>
                            <li>
                              <div>Net Equity:</div>
                              <div>{{ totalOwnerEquity | CurrencyFormatter | IsValueExist }}</div>
                            </li>
                            <li>
                              <div>Estimated LTV:</div>
                              <div>{{ estimatedLtv }}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr />

                      <!-- Mortgage Information -->

                      <div class="row">
                        <div class="col-lg-12">
                          <h6 class="my-3 headWrap">Mortgage Information</h6>
                          <div
                            *ngIf="activeMortgageHistory && activeMortgageHistory.length > 0; else noMortgageHistory"
                          >
                            <mat-tab-group>
                              <div *ngFor="let mortgage of activeMortgageHistory | slice: 0:4; let i = index">
                                <mat-tab>
                                  <ng-template mat-tab-label>
                                    {{ i + 1 | NumberToTextConverter }} Mortgage
                                  </ng-template>
                                  <ul class="pl-0 w-100 prop_char mt-3">
                                    <li>
                                      <div>Principal Amount:</div>
                                      <div>{{ mortgage.MortgagePrincipal | CurrencyFormatter | IsValueExist }}</div>
                                    </li>
                                    <li>
                                      <div>Interest Rate:</div>
                                      <div>{{ mortgage.MortgageRate | number | IsValueExist }}%</div>
                                    </li>
                                    <li>
                                      <div>Loan Term:</div>
                                      <div>{{ mortgage.MortgageTerm | IsValueExist }}</div>
                                    </li>
                                    <li>
                                      <div>Loan Date:</div>
                                      <div>
                                        {{
                                          (mortgage.MortgageDocumentDate
                                            ? mortgage.MortgageDocumentDate
                                            : mortgage.MortgageRecordingDate
                                          )
                                            | date: 'mediumDate'
                                            | IsValueExist
                                        }}
                                      </div>
                                    </li>
                                    <li>
                                      <div>Mortgage Type:</div>
                                      <div>{{ mortgage.MortgageLoanType | IsValueExist }}</div>
                                    </li>
                                    <li>
                                      <div>Est. Loan Balance:</div>
                                      <div>{{ mortgage.LoanBalanceAtMonthEnd | CurrencyFormatter | IsValueExist }}</div>
                                    </li>
                                    <li>
                                      <div>Est. Monthly Payment:</div>
                                      <div>{{ mortgage.MonthlyEMI | CurrencyFormatter | IsValueExist }}</div>
                                    </li>
                                    <li>
                                      <div>Lender:</div>
                                      <div>{{ mortgage.MortgageLenderName | IsValueExist }}</div>
                                    </li>
                                  </ul>
                                </mat-tab>
                              </div>
                            </mat-tab-group>
                          </div>
                          <ng-template #noMortgageHistory>
                            <div class="flex_center notFound text-center mt-3 mb-3">
                              <div>
                                <h4>No mortgage information available</h4>
                              </div>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div
                      *ngIf="
                        propertyInfo &&
                        propertyInfo?.Lst_StandardStatus !== 'Active' &&
                        propertyInfo?.Lst_StandardStatus !== 'Active Under Contract' &&
                        propertyInfo.Lst_StandardStatus !== 'Pending' &&
                        listingData.StandardStatus != 'Pending' &&
                        listingData.StandardStatus != 'Active' &&
                        listingData.StandardStatus != 'Active Under Contract'
                      "
                    ></div>
                    <hr />

                    <hr />

                    <!-- School Information -->

                    <div class="sectioninfo mt-2" id="schools" #schools [ngStyle]="onScrollEvent">
                      <div class="row">
                        <div class="col-lg-12">
                          <mat-accordion>
                            <mat-expansion-panel
                              class="expand-head"
                              (opened)="getSchoolDistrictsForProperty()"
                              (closed)="onCloseSchoolInfo()"
                              (afterExpand)="scroll('schools')"
                              #schools
                            >
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                  <h4 class="headTitle">
                                    <img src="../../../assets/images/schoolInfo.svg" class="mr-2" width="22" /> School
                                    Information
                                  </h4>
                                </mat-panel-title>
                              </mat-expansion-panel-header>
                              <div *ngIf="schoolsForPropertyLoaderStatus">
                                <div class="flex_center fullHeight">
                                  <mat-spinner [diameter]="30" class="mr-3"></mat-spinner> Please wait while we load
                                </div>
                              </div>
                              <div class="schoolInfo" *ngIf="!schoolsForPropertyLoaderStatus">
                                <div
                                  *ngIf="
                                    activeSchoolDistrictsForProperty && activeSchoolDistrictsForProperty.length > 0
                                  "
                                >
                                  <p class="mb-1">School District Names:</p>
                                  <mat-chip-list aria-label="School Districts">
                                    <div *ngFor="let district of activeSchoolDistrictsForProperty">
                                      <mat-chip color="primary" selected>
                                        <span
                                          class="badge"
                                          *ngIf="district.DistrictType && district.DistrictType != ''"
                                        >
                                          {{ district.DistrictType.charAt(0) }}
                                        </span>
                                        {{ district.SchoolDistrictName }}
                                      </mat-chip>
                                    </div>
                                  </mat-chip-list>
                                  <div *ngIf="activeSchoolsForProperty && activeSchoolsForProperty.length > 0">
                                    <div class="row">
                                      <mat-card class="col-sm-6" *ngFor="let school of activeSchoolsForProperty">
                                        <ul>
                                          <li>
                                            <h4>{{ school.SchoolName }}</h4>
                                          </li>
                                          <li>
                                            <img src="../../../assets/images/mapPin.svg" width="16" />
                                            <span class="ml-1"
                                              >{{ school.StreetAddress1 }} {{ school.City }}
                                              {{ school.StateOrProvince }} {{ school.PostalCode }}</span
                                            >
                                          </li>
                                          <li>
                                            <img src="../../../assets/images/phone.svg" width="13" />
                                            <span class="ml-1">{{ school.ContactPhone }}</span>
                                          </li>
                                          <li>
                                            <img src="../../../assets/images/schoolCap.svg" width="16" />
                                            <span class="ml-1">{{ school.SchoolGradeLevel }}</span>
                                          </li>
                                          <li class="schoolType">
                                            <img src="../../../assets/images/schoolType.svg" width="40" />
                                            <div [ngClass]="{ pvt: school.PublicOrPrivate === 'Private School' }">
                                              {{ school.PublicOrPrivate }}
                                            </div>
                                          </li>
                                        </ul>
                                      </mat-card>
                                    </div>
                                  </div>
                                </div>
                                <div class="text-center mt-2" style="font-style: italic; color: gray">
                                  School data source: National Center for Educational Statistics (NCES)
                                </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </div>

                    <!-- Local Trends -->

                    <div class="sectioninfo mt-5" id="trends" [ngStyle]="onScrollEvent">
                      <div class="row">
                        <div class="col-lg-12">
                          <mat-accordion>
                            <mat-expansion-panel
                              (opened)="getLocalTrends(activePropertyDetails.PostalCode)"
                              (closed)="onCloseLocalTrends()"
                              (afterExpand)="scroll('localTrends')"
                              class="expand-head"
                              #localTrends
                            >
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                  <h4 class="headTitle">
                                    <img src="../../../assets/images/trendsInfo.svg" class="mr-2" width="22" />
                                    Local Market Insights for
                                    {{
                                      (activePropertyDetails.PostalCode | IsValueExist) !== '--'
                                        ? activePropertyDetails.PostalCode
                                        : ''
                                    }}
                                  </h4>
                                </mat-panel-title>
                              </mat-expansion-panel-header>
                              <div *ngIf="localTrendsLoaderStatus">
                                <div class="flex_center fullHeight">
                                  <mat-spinner [diameter]="30" class="mr-3"></mat-spinner> Please wait while we load
                                </div>
                              </div>
                              <div *ngIf="!localTrendsLoaderStatus">
                                <p class="bold ml-3">Market Insights provided by PropMix.io LLC</p>
                                <iframe
                                  *ngIf="activeLocalTrendsForZip"
                                  [src]="activeLocalTrendsForZip"
                                  height="2460"
                                  width="100%"
                                ></iframe>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </div>

                    <ng-template #distressedInfoContent>
                      <div class="common-div">
                        <div class="sectioninfo mt-5" id="more" [ngStyle]="onScrollEvent">
                          <h4 class="headTitle">
                            <img src="../../../assets/images/distressed_data.png" class="mr-2" width="22" />
                            <span
                              *ngIf="
                                ['Auction', 'Hudlisting', 'Pre-Foreclosure', 'Bankowned'].includes(
                                  propertyInfo?.saletype
                                ) ||
                                ['Auction', 'Hudlisting', 'Pre-Foreclosure', 'Bankowned'].includes(
                                  activePropertyDetails?.saletype
                                )
                              "
                            >
                              {{ propertyInfo?.saletype || activePropertyDetails?.saletype }} Details
                            </span>

                            <span
                              *ngIf="
                                ((propertyInfo?.saletype &&
                                  propertyInfo?.saletype.includes(',') &&
                                  propertyInfo?.saletype.includes('Auction')) ||
                                  (activePropertyDetails?.saletype &&
                                    activePropertyDetails?.saletype.includes(',') &&
                                    activePropertyDetails?.saletype.includes('Auction'))) &&
                                isBeforeLastAuctionDate(
                                  propertyInfo.Fcl_lastauctionDate || activePropertyDetails.Fcl_lastauctionDate
                                )
                              "
                            >
                              Auction Details</span
                            >

                            <span
                              *ngIf="
                                ((propertyInfo?.saletype &&
                                  propertyInfo?.saletype.includes(',') &&
                                  propertyInfo?.saletype.includes('Auction')) ||
                                  (activePropertyDetails?.saletype &&
                                    activePropertyDetails?.saletype.includes(',') &&
                                    activePropertyDetails?.saletype.includes('Auction'))) &&
                                !isBeforeLastAuctionDate(
                                  propertyInfo.Fcl_lastauctionDate || activePropertyDetails.Fcl_lastauctionDate
                                )
                              "
                            >
                              Distress Details</span
                            >
                          </h4>

                          <div
                            class="flex_center notFound text-center mt-3 mb-3"
                            *ngIf="!activeDistressedProperty || !distressedPropertyStatus; else noDistressInformation"
                          >
                            <div>
                              <h4>No distress information available</h4>
                            </div>
                          </div>

                          <ng-template #noDistressInformation>
                            <!-- Distress Information -->

                            <div class="row">
                              <div class="col-lg-12">
                                <h6 class="my-3 headWrap">Distress Information</h6>
                                <ul class="pl-0 w-100 prop_char">
                                  <li>
                                    <div>Distress Status:</div>
                                    <div>{{ activeDistressedProperty.DistressStatus | IsValueExist }}</div>
                                  </li>
                                  <li>
                                    <div>Distress Start Date:</div>
                                    <div>
                                      {{
                                        activeDistressedProperty.DistressStartDate | date: 'mediumDate' | IsValueExist
                                      }}
                                    </div>
                                  </li>
                                  <li>
                                    <div>Default Amount:</div>
                                    <div>
                                      {{ activeDistressedProperty.DefaultAmount | CurrencyFormatter | IsValueExist }}
                                    </div>
                                  </li>
                                  <li>
                                    <div>Escrow Amount:</div>
                                    <div>
                                      {{ activeDistressedProperty.EscrowAmount | CurrencyFormatter | IsValueExist }}
                                    </div>
                                  </li>
                                  <li>
                                    <div>Last Payment Amount:</div>
                                    <div>
                                      {{
                                        activeDistressedProperty.LastPaymentAmount | CurrencyFormatter | IsValueExist
                                      }}
                                    </div>
                                  </li>
                                  <li></li>
                                  <li>
                                    <div>Total Due Amount:</div>
                                    <div>
                                      {{ activeDistressedProperty.TotalDueAmount | CurrencyFormatter | IsValueExist }}
                                    </div>
                                  </li>
                                  <li>
                                    <div>Due Date:</div>
                                    <div>
                                      {{
                                        activeDistressedProperty.ForeclosureJudgementDate
                                          | date: 'mediumDate'
                                          | IsValueExist
                                      }}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div
                              *ngIf="
                                (propertyInfo?.saletype &&
                                  propertyInfo?.saletype.includes(',') &&
                                  propertyInfo?.saletype.includes('Auction')) ||
                                (propertyInfo?.saletype && propertyInfo?.saletype == 'Auction') ||
                                (activePropertyDetails?.saletype &&
                                  activePropertyDetails?.saletype.includes(',') &&
                                  activePropertyDetails?.saletype.includes('Auction')) ||
                                (activePropertyDetails?.saletype && activePropertyDetails?.saletype == 'Auction')
                              "
                            >
                              <span
                                *ngIf="
                                  isBeforeLastAuctionDate(
                                    propertyInfo.Fcl_lastauctionDate || activePropertyDetails.Fcl_lastauctionDate
                                  )
                                "
                              >
                                <ng-container *ngTemplateOutlet="auctionInfoContent"></ng-container>
                              </span>
                            </div>

                            <!-- Mortgage Information -->

                            <div
                              class="row"
                              *ngIf="
                                ['Pre-Foreclosure'].includes(propertyInfo?.saletype) ||
                                ['Pre-Foreclosure'].includes(activePropertyDetails?.saletype) ||
                                propertyInfo?.Lst_speciallistingconditions == 'Short Sale' ||
                                activePropertyDetails?.Lst_speciallistingconditions == 'Short Sale'
                              "
                            >
                              <div class="col-lg-12">
                                <h6 class="my-3 headWrap">Mortgage Information</h6>
                                <ul class="pl-0 w-100 prop_char">
                                  <li>
                                    <div>Lender Name:</div>
                                    <div>{{ activeDistressedProperty.MortgageLenderName | IsValueExist }}</div>
                                  </li>
                                  <li>
                                    <div>Principal:</div>
                                    <div>
                                      {{
                                        activeDistressedProperty.MortgagePrincipal | CurrencyFormatter | IsValueExist
                                      }}
                                    </div>
                                  </li>
                                  <li>
                                    <div>Lender Address:</div>
                                    <div
                                      *ngIf="
                                        (activeDistressedProperty.MortgageLenderStreetAddress | IsValueExist) ===
                                          '--' &&
                                          (activeDistressedProperty.MortgageLenderCity | IsValueExist) === '--' &&
                                          (activeDistressedProperty.MortgageLenderStateOrProvince | IsValueExist) ===
                                            '--' &&
                                          (activeDistressedProperty.MortgageLenderPostalCode | IsValueExist) === '--';
                                        else noLenderAddress
                                      "
                                    >
                                      --
                                    </div>
                                    <ng-template #noLenderAddress>
                                      <div>
                                        {{ activeDistressedProperty.MortgageLenderStreetAddress | IsValueExist }},
                                        {{ activeDistressedProperty.MortgageLenderCity | IsValueExist }},
                                        {{ activeDistressedProperty.MortgageLenderStateOrProvince | IsValueExist }},
                                        {{ activeDistressedProperty.MortgageLenderPostalCode | IsValueExist }}
                                      </div>
                                    </ng-template>
                                  </li>
                                  <li>
                                    <div>Contract Date:</div>
                                    <div>
                                      {{
                                        activeDistressedProperty.MortgageContractDate
                                          | date: 'mediumDate'
                                          | IsValueExist
                                      }}
                                    </div>
                                  </li>
                                  <li>
                                    <div>Lender Contact:</div>
                                    <div>{{ activeDistressedProperty.MortgageLenderPhone | IsValueExist }}</div>
                                  </li>
                                  <li>
                                    <div>Balance:</div>
                                    <div>
                                      {{ activeDistressedProperty.MortgageBalance | CurrencyFormatter | IsValueExist }}
                                    </div>
                                  </li>
                                  <li>
                                    <div>Lender Notes:</div>
                                    <div>{{ activeDistressedProperty.MortgageLenderNotes | IsValueExist }}</div>
                                  </li>
                                  <li>
                                    <div>Document Id:</div>
                                    <div>{{ activeDistressedProperty.MortgageRecordingDocumentId | IsValueExist }}</div>
                                  </li>
                                  <li></li>
                                  <li>
                                    <div>Book #:</div>
                                    <div>{{ activeDistressedProperty.MortgageRecordingBook | IsValueExist }}</div>
                                  </li>
                                  <li></li>
                                  <li>
                                    <div>Page #:</div>
                                    <div>{{ activeDistressedProperty.MortgageRecordingPage | IsValueExist }}</div>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <!-- Trustee Information -->

                            <div
                              class="row"
                              *ngIf="
                                propertyInfo?.Fcl_Status == 'AUCTION' ||
                                propertyInfo?.Lst_speciallistingconditions == 'Short Sale'
                              "
                            >
                              <div class="col-lg-12">
                                <h6 class="my-3 headWrap">Trustee Information</h6>
                                <ul class="pl-0 w-100 prop_char">
                                  <li>
                                    <div>Name:</div>
                                    <div>{{ activeDistressedProperty.TrusteeName | IsValueExist }}</div>
                                  </li>
                                  <li>
                                    <div>Trustee File Number:</div>
                                    <div>{{ activeDistressedProperty.TrusteeFileNumber | IsValueExist }}</div>
                                  </li>
                                  <li>
                                    <div>Address:</div>
                                    <div
                                      *ngIf="
                                        (activeDistressedProperty.TrusteeStreetAddress | IsValueExist) === '--' &&
                                          (activeDistressedProperty.TrusteeCity | IsValueExist) === '--' &&
                                          (activeDistressedProperty.TrusteeStateOrProvince | IsValueExist) === '--' &&
                                          (activeDistressedProperty.TrusteePostalCode | IsValueExist) === '--';
                                        else noTrusteeAddress
                                      "
                                    >
                                      --
                                    </div>
                                    <ng-template #noTrusteeAddress>
                                      <div>
                                        {{ activeDistressedProperty.TrusteeStreetAddress | IsValueExist }},
                                        {{ activeDistressedProperty.TrusteeCity | IsValueExist }},
                                        {{ activeDistressedProperty.TrusteeStateOrProvince | IsValueExist }},
                                        {{ activeDistressedProperty.TrusteePostalCode | IsValueExist }}
                                      </div>
                                    </ng-template>
                                  </li>
                                  <li></li>
                                  <li>
                                    <div>Phone:</div>
                                    <div>{{ activeDistressedProperty.TrusteePhone | IsValueExist }}</div>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <ng-template #auctionInfoContent>
                              <div class="row" *ngIf="auctionList && auctionList.length > 0">
                                <div class="col-lg-12">
                                  <h6 class="my-3 headWrap">Auction Information</h6>
                                  <div class="table_base">
                                    <div class="table-responsive table_wrap">
                                      <table
                                        mat-table
                                        [dataSource]="auctionList | slice: 0:limit"
                                        class="mat-elevation-z2 w-100"
                                      >
                                        <ng-container matColumnDef="slNo">
                                          <th mat-header-cell *matHeaderCellDef>No.</th>
                                          <td mat-cell *matCellDef="let element">
                                            {{ auctionList.indexOf(element) + 1 }}
                                          </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Auction Date">
                                          <th mat-header-cell *matHeaderCellDef>Auction Date</th>
                                          <td mat-cell *matCellDef="let element">
                                            {{ element.AuctionDate | IsValueExist }}
                                          </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Auction Time">
                                          <th mat-header-cell *matHeaderCellDef>Auction Time</th>
                                          <td mat-cell *matCellDef="let element">
                                            {{ element.AuctionTime | IsValueExist }}
                                          </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Auction Site Address">
                                          <th mat-header-cell *matHeaderCellDef>Auction Site Address</th>
                                          <td mat-cell *matCellDef="let element">
                                            {{ element.AuctionSiteAddress | IsValueExist }}
                                          </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Auction Site City">
                                          <th mat-header-cell *matHeaderCellDef>Auction Site City</th>
                                          <td mat-cell *matCellDef="let element">
                                            {{ element.AuctionSiteCity | IsValueExist }}
                                          </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Auction Site Postal Code">
                                          <th mat-header-cell *matHeaderCellDef>Auction Site Postal Code</th>
                                          <td mat-cell *matCellDef="let element">
                                            {{ element.AuctionSitePostalCode | IsValueExist }}
                                          </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="auctionDisplayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: auctionDisplayedColumns"></tr>
                                      </table>
                                    </div>
                                  </div>
                                  <div>
                                    <button
                                      mat-button
                                      *ngIf="auctionList.length > 1"
                                      (click)="toggle()"
                                      style="float: right"
                                    >
                                      View {{ showAuction ? 'less' : 'more' }}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                          </ng-template>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>

                <div class="col-sm-12 col-md-5 col-lg-4 m-none" *ngIf="this.companyName != 'valuetest'">
                  <div class="propdetails_rightsec d-cta" *ngIf="canViewAgentContactInfo">
                    <mat-accordion multi="true">
                      <mat-expansion-panel
                        class="contact-agent"
                        [expanded]="true"
                        *ngIf="
                          ['active', 'pending', 'active under contract'].includes(
                            propertyInfo?.Lst_StandardStatus?.toLowerCase() ||
                              activePropertyDetails?.Lst_StandardStatus?.toLowerCase() ||
                              listingData?.StandardStatus?.toLowerCase()
                          )
                        "
                      >
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <h4 class="mb-0">Contact Agent</h4>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-agent-contact> </app-agent-contact>
                      </mat-expansion-panel>
                      <mat-expansion-panel
                        [expanded]="true"
                        *ngIf="
                          ['active', 'pending', 'active under contract'].includes(
                            propertyInfo?.Lst_StandardStatus?.toLowerCase() ||
                              activePropertyDetails?.Lst_StandardStatus?.toLowerCase() ||
                              listingData?.StandardStatus?.toLowerCase()
                          )
                        "
                      >
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <h4 class="mb-0">Payment Calculator</h4>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="bold ml-3 mb-0">Calculator and Data provided by PropMix.io LLC</p>
                        <app-payment-calculator class="pt-4 pt-md-3 pt-lg-4"> </app-payment-calculator>
                      </mat-expansion-panel>
                      <mat-expansion-panel
                        class="contact-agent"
                        [expanded]="true"
                        *ngIf="!isMyHomeClicked && !isOffmarketProperty"
                      >
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <h4 class="mb-0">Is this your home?</h4>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="home-claim">Track this home's value with real time information</p>
                        <div class="btn-p">
                          <button
                            *ngIf="
                              this.userType == 'investor' && !propertyInfo?.homeId && !activePropertyDetails?.homeId
                            "
                            mat-flat-button
                            color="primary"
                            class="get-approved btn-block claim-btn"
                            (click)="answerQuestions()"
                          >
                            Claim your home
                          </button>
                          <button
                            *ngIf="propertyInfo?.homeId || activePropertyDetails?.homeId"
                            mat-flat-button
                            color="primary"
                            class="get-approved btn-block claim-btn"
                            (click)="manageMyProperty()"
                          >
                            Manage your home
                          </button>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>

                  <br />
                  <div class="propdetails_rightsec d-cta" *ngIf="isOffmarketProperty && displayCta">
                    <mat-accordion multi="true">
                      <mat-expansion-panel class="contact-agent" [expanded]="true">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <h4 class="mb-0">Is this your home?</h4>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="home-claim">Track this home's value and nearby sales activity</p>
                        <div class="btn-p">
                          <button
                            mat-flat-button
                            color="primary"
                            class="get-approved btn-block claim-btn"
                            (click)="answerQuestions()"
                          >
                            Claim your home
                          </button>
                        </div>

                        <hr class="hr" />
                        <div class="btn-p">
                          <button
                            mat-stroked-button
                            color="primary"
                            class="get-approved mr-3 btn-block claim-btn"
                            (click)="openContactConsultant()"
                          >
                            Request a selling agent
                          </button>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <br />
                  <div></div>
                </div>
              </div>
              <app-company-footer></app-company-footer>
              <div
                class="footer_menu d-block d-md-none"
                *ngIf="!(companyAppConfig?.valueReport?.isEnabled || companyAppConfig?.opportunityScanner?.isEnabled)"
              >
                <button mat-stroked-button color="primary" class="get-approved mr-3" (click)="preApprovedAction()">
                  {{ appState.companyInfoValue?.ctaConfig?.getPreApproval?.agentCtaButtonLabel || 'Get Pre-Approved' }}
                </button>
                <button mat-flat-button color="primary" class="get-approved mr-4" (click)="schedulePropertyVisit()">
                  {{ appState.companyInfoValue?.ctaConfig?.scheduleVisit?.agentCtaButtonLabel || 'Schedule Showing' }}
                </button>
              </div>
            </div>
          </div>
          <!-- Multiple Property Details -->

          <div class="propdetails_base container" *ngIf="showMultipleProperty && isPropertyDataExists == true">
            <div class="propdetails_wrap">
              <div class="scroller">
                <cdk-virtual-scroll-viewport class="mb-1 fullHeightVH" itemSize="100">
                  <div *cdkVirtualFor="let property of multiplePropertyList; let i = index; trackBy: trackByFn">
                    <mat-card class="mb-1" (click)="showMultipleDetails(property)">
                      <div class="row">
                        <ng-container>
                          <div class="col-lg-6">
                            <div class="numberWrap">
                              <div class="number">{{ i + 1 }}</div>
                              <h6 class="mb-0 truncate">
                                {{ property.Address | IsValueExist }}
                              </h6>
                              <small class="badge text-white">{{ property.PropertySubType | IsValueExist }}</small>
                              <small class="badge text-white ml-1 bg-secondary">
                                <ng-container
                                  *ngIf="(propertySheetFacade.getAppliedFilterStatus() | async) && property.isFiltered"
                                >
                                  Filtered
                                </ng-container>
                                <ng-container
                                  *ngIf="(propertySheetFacade.getAppliedFilterStatus() | async) && !property.isFiltered"
                                >
                                  Not Filtered
                                </ng-container>
                              </small>
                              <small
                                *ngIf="property.propensityType && authorizeService.canView('propensityFilter')"
                                class="badge text-white ml-1"
                                [ngStyle]="{
                                  'background-color':
                                    property.propensityType === 'cold'
                                      ? '#17a2b8'
                                      : property.propensityType === 'warm'
                                      ? '#ffc107'
                                      : '#ff0000'
                                }"
                              >
                                {{ property.propensityType | uppercase }}</small
                              >
                              <small
                                *ngIf="
                                  property && property.Lst_StandardStatus && property.Lst_StandardStatus === 'Sold'
                                "
                                class="badge text-white ml-1"
                                [ngStyle]="{ 'background-color': '#3f51b5' }"
                              >
                                {{ property.Lst_StandardStatus | uppercase }}</small
                              >
                              <small
                                *ngIf="
                                  property &&
                                  property.Lst_StandardStatus &&
                                  (property.Lst_StandardStatus === 'Active' ||
                                    property.Lst_StandardStatus === 'Active Under Contract')
                                "
                                class="badge text-white ml-1"
                                [ngStyle]="{ 'background-color': '#8bc34a' }"
                              >
                                {{ property.Lst_StandardStatus | uppercase }}</small
                              >
                              <small
                                *ngIf="
                                  property && property.Lst_StandardStatus && property.Lst_StandardStatus === 'Pending'
                                "
                                class="badge text-white ml-1"
                                [ngStyle]="{ 'background-color': '#f69832' }"
                              >
                                {{ property.Lst_StandardStatus | uppercase }}</small
                              >
                              <small
                                *ngIf="
                                  property &&
                                  property.Lst_StandardStatus &&
                                  (property.Lst_StandardStatus === 'NULL' ||
                                    property.Lst_StandardStatus === 'Cancel/Withdrawn' ||
                                    property.Lst_StandardStatus === 'Contingent' ||
                                    property.Lst_StandardStatus === 'Delisted' ||
                                    property.Lst_StandardStatus === 'Unknown' ||
                                    property.Lst_StandardStatus === 'Unlisted')
                                "
                                class="badge text-white ml-1"
                                [ngStyle]="{ 'background-color': '#833f2a' }"
                              >
                                {{ property.Lst_StandardStatus | uppercase }}</small
                              >
                              <small class="flipBadge ml-1" *ngIf="(property.Lst_FlipDuration | IsValueExist) != '--'"
                                >FLIP</small
                              >
                            </div>
                          </div>
                          <div class="col-lg-4 col-9 text-center mobTextl">
                            <ul class="prop_highlight pl-0 mb-0">
                              <li [matTooltip]="'Bed'">
                                <img src="../../assets/images/bed.svg" width="18" />
                                <p>{{ property.BedroomsTotal | IsValueExist }}</p>
                              </li>
                              <li [matTooltip]="'Bath'">
                                <img src="../../assets/images/bath.svg" width="18" />
                                <p>{{ property.BathroomsTotalInteger | IsValueExist }}</p>
                              </li>
                              <li [matTooltip]="'Living Area'">
                                <img src="../../assets/images/living_area.svg" width="18" />
                                <p>
                                  {{ property.LivingArea | IsValueExist }}
                                  <small *ngIf="property.LivingArea"> Sq.ft</small>
                                </p>
                              </li>
                              <li [matTooltip]="'Lot Size Square Feet'">
                                <img src="../../assets/images/land_area.svg" width="18" />
                                <p [innerHTML]="property.LotSizeSquareFeet | SqftToAcresConverter"></p>
                              </li>
                            </ul>
                          </div>
                        </ng-container>
                        <div class="col-lg-2 col-3 text-right">
                          <button
                            mat-flat-button
                            class="fav"
                            (click)="followProperty(property); $event.stopPropagation()"
                          >
                            <mat-icon class="material-icons-new outline-favorite" *ngIf="!property.isFavorite">
                            </mat-icon>
                            <mat-icon *ngIf="property.isFavorite">favorite</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-card>
                  </div>
                  <app-company-footer></app-company-footer>
                </cdk-virtual-scroll-viewport>
              </div>
            </div>
          </div>

          <div class="text-center no_property_img" style="margin: 140px" *ngIf="isPropertyDataExists == false">
            <img src="../../../../../assets/images/spend.jpg" width="150" alt="property" class="opacity-80" />
            <h6 class="mt-4 pl-1 pl-md-0">
              Requested property could not be found
              <small class="btn-block mt-2"> Requested property not found</small>
            </h6>
            <hr />
            <button
              mat-flat-button
              type="button"
              color="primary"
              class="material-icons pull-center"
              (click)="onClose()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
