import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import constants from 'src/constant.json';
import { environment } from '../../environments/environment';
import { ProspektrApi } from '../_shared/api/prospektr.api';
import { QuestionnaireApi } from '../_shared/api/questionnaire.api';
import { UserState } from '../_shared/state/user.state';
import { AppState } from '../app.state';
import { UserAddress, UserProfile } from '../shared/interface/user-details';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private http: HttpClient,
    private prospektrApi: ProspektrApi,
    private userState: UserState,
    private snackBar: MatSnackBar,
    private appState: AppState,
    private QuestionsApi: QuestionnaireApi
  ) {}

  updateUserBasicInfo(userProfile: UserProfile, userId = null, contactEmailChange = null): Observable<any> {
    return new Observable((observer) => {
      const params = {
        userProfile: {
          email: userProfile.email?.toLowerCase(),
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          contact: userProfile.contact,
          profileImage: userProfile.profileImage,
          isSelfBranded: userProfile.isSelfBranded,
          loanApplicationUrl: userProfile.loanApplicationUrl,
          workPlace: userProfile.workPlace,
          jobTitle: userProfile.jobTitle,
          nmlsNumber: userProfile.nmlsNumber,
          nvLicenseList: userProfile.nvLicenseList,
          contactEmail: userProfile.contactEmail,
          contactEmailChange: contactEmailChange,
          signature: userProfile.signature,
        },
      };
      if (userId) {
        params['userId'] = userId;
      }
      if (userProfile.activeStatus == true || userProfile.activeStatus == false) {
        params.userProfile['activeStatus'] = userProfile.activeStatus;
      }
      this.http
        .post(environment.prospektrBackendBaseURL + constants.API.updateUser, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.message) {
              observer.next({ message: res.message, status: true });
            }
          },
          (err) => {
            observer.next({
              message: err.error && err.error.message ? err.error.message : 'Error occurred',
              status: false,
            });
          }
        );
    });
  }

  updateUserAppInfo(placeDetails: UserAddress): Observable<any> {
    return new Observable((observer) => {
      const params = {
        workPlace: {
          formattedAddress: placeDetails.formattedAddress,
          addressComponents: placeDetails.addressComponents,
          userLocation: placeDetails.userLocation,
        },
      };
      this.http.post(environment.prospektrBackendBaseURL + constants.API.updateUserAppDetails, params).subscribe(
        (res: { Message: string }) => {
          if (res && res.Message) {
            observer.next({ message: res.Message, status: true });
          }
        },
        (err) => {
          observer.next({
            message: err.error && err.error.message ? err.error.message : 'Error occurred',
            status: false,
          });
        }
      );
    });
  }

  updateUserValueTestTiers(valueReportSearchTiers): Observable<any> {
    return new Observable((observer) => {
      const params = {
        valueReportSearchTiers: valueReportSearchTiers,
      };
      this.http.post(environment.prospektrBackendBaseURL + constants.API.updateUserValueTestTiers, params).subscribe(
        (res: { Message: string }) => {
          if (res && res.Message) {
            observer.next({ message: res.Message, status: true });
          }
        },
        (err) => {
          observer.next({
            message: err.error && err.error.message ? err.error.message : 'Error occurred',
            status: false,
          });
        }
      );
    });
  }

  updateUserPassword(newPassword: string): Observable<any> {
    return new Observable((observer) => {
      const params = {
        password: newPassword,
      };
      this.http.post(environment.prospektrBackendBaseURL + constants.API.updateUserPassword, params).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  get defaultProfileImg() {
    return constants.userAvatar;
  }

  saveNotificationSubscription(params): Observable<any> {
    return new Observable((observer) => {
      this.http.post(environment.prospektrBackendBaseURL + constants.API.setMonitorSpec, params).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  getNotificationSubscription(): Observable<any> {
    return new Observable((observer) => {
      this.http.post(environment.prospektrBackendBaseURL + constants.API.getMonitorSpec, null).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  listBusinessZip(): Observable<any> {
    return new Observable((observer) => {
      this.http.post(environment.prospektrBackendBaseURL + constants.API.listBusinessZip, null).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  setBusinessZip(params): Observable<any> {
    return new Observable((observer) => {
      this.http.post(environment.prospektrBackendBaseURL + constants.API.setBusinessZip, params).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  deleteBusinessZip(params): Observable<any> {
    return new Observable((observer) => {
      this.http.post(environment.prospektrBackendBaseURL + constants.API.deleteBusinessZip, params).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  getMLSBoards(input, omitMlsVerification = false): Observable<any> {
    return new Observable((observer) => {
      let request = {
        state: input,
        omitMlsVerification: omitMlsVerification,
      };
      let uniqueMLSBoards = [];
      observer.next({ isLoading: true });
      this.prospektrApi
        .getMLSBoards(request)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false, mlsBoard: uniqueMLSBoards });
            observer.complete();
          })
        )
        .subscribe((res) => {
          uniqueMLSBoards = res.data;
        });
    });
  }

  getConnectedAgentsMlsList(): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getConnectedAgentsMlsList()
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            if (res && res.status === 'OK' && res.data && res.data.length > 0) {
              const updatedMLSList = res.data.flatMap((element) => {
                if (element?.mlsList?.length > 0) {
                  return element.mlsList.map((mls) => {
                    // Apply state transformation using constants.STATES
                    for (const state in constants.STATES) {
                      if (mls.state === state) {
                        mls.stateOrProvince = mls.state;
                        mls.state = constants.STATES[state];
                      }
                    }
                    // Return transformed mls with parent user data
                    return {
                      ...mls,
                      userId: element._id,
                      email: element.email,
                      firstName: element.firstName,
                      lastName: element.lastName,
                      userType: element.userType,
                    };
                  });
                }
                return []; // Return empty array if no mlsList
              });
              this.userState.connectedAgentsMlsListValue = updatedMLSList;
            } else {
              this.userState.connectedAgentsMlsListValue = [];
            }
          },
          (err) => {
            this.userState.connectedAgentsMlsListValue = [];
          }
        );
    });
  }

  getAppraisersMlsList(): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getAppraisersMlsList()
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            if (res && res.status === 'OK' && res.data && res.data.length > 0) {
              const updatedMLSList = res.data.flatMap((element) => {
                if (element?.mlsList?.length > 0) {
                  return element.mlsList.map((mls) => {
                    // Apply state transformation using constants.STATES
                    for (const state in constants.STATES) {
                      if (mls.state === state) {
                        mls.stateOrProvince = mls.state;
                        mls.state = constants.STATES[state];
                      }
                    }
                    // Return transformed mls with parent appraiser data
                    return {
                      ...mls,
                      userId: element._id,
                      email: element.email,
                      firstName: element.firstName,
                      lastName: element.lastName,
                      userType: element.userType,
                    };
                  });
                }
                return []; // Return empty array if no mlsList
              });
              this.userState.appraisersMlsListValue = updatedMLSList;
            } else {
              this.userState.appraisersMlsListValue = [];
            }
          },
          (err) => {
            this.userState.appraisersMlsListValue = [];
          }
        );
    });
  }

  getAgentMLSList(): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getAgentMls()
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            if (res && res.status === 'success' && res.data && res.data.mlsList?.length > 0) {
              const updatedMLSList = res.data.mlsList.map((mls) => {
                // Apply state transformation using constants.STATES
                for (const state in constants.STATES) {
                  if (mls.state === state) {
                    mls.stateOrProvince = mls.state;
                    mls.state = constants.STATES[state];
                  }
                }
                // Add parent user data to mls object
                return {
                  ...mls,
                  userId: res.data._id,
                  email: res.data.email,
                  firstName: res.data.firstName,
                  lastName: res.data.lastName,
                  userType: res.data.userType,
                };
              });
              this.userState.mlsListValue = updatedMLSList;
            } else {
              this.userState.mlsListValue = [];
            }
          },
          (err) => {
            this.userState.mlsListValue = [];
          }
        );
    });
  }

  saveAgentMls(params): Observable<any> {
    return new Observable((observer) => {
      this.prospektrApi.saveAgentMls(params).subscribe(
        (res) => {
          observer.next(res);
          observer.complete();
        },
        (err) => {
          observer.next(err.error);
        }
      );
    });
  }

  deleteAgentMls(params): Observable<any> {
    return new Observable((observer) => {
      this.prospektrApi.deleteAgentMls(params).subscribe(
        (res) => {
          observer.next(res);
          observer.complete();
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  createBusinessAreas(params): Observable<any> {
    let response = [];
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .createBusinessAreas(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false, response: response });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            if (res && res.data) {
              let addBusinessList = {
                area: res.data.area,
                areaType: res.data.areaType,
                exclusive: res.data.exclusive,
                _id: res.data._id,
                approvalDate: res.data?.approvalDate,
              };
              let businessAreaLisValue: any = this.userState.businessAreaListValue;
              businessAreaLisValue.push(addBusinessList);
              this.userState.businessAreaListValue = businessAreaLisValue;
              response = res;
              this.openSnackBar(res.message, true);
              observer.next(res);
            } else {
              response = res;
            }
          },
          (err) => {
            observer.error(err.error);
          }
        );
    });
  }

  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? 'snackbar-success' : 'snackbar-error'],
        verticalPosition: 'top',
      });
    }
  }

  getBusinessAreas(): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getBusinessAreas()
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            if (res && res.status && res.status === 'OK' && res.data) {
              this.userState.businessAreaListValue = res.data;
            } else {
              this.userState.businessAreaListValue = [];
            }
          },
          (err) => {
            this.userState.businessAreaListValue = [];
          }
        );
    });
  }

  deleteBusinessArea(params): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .deleteBusinessArea(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            let businessAreaListValue: any = this.userState.businessAreaListValue;
            let filteredBusinessArea = businessAreaListValue.filter(function (item) {
              return item._id !== params;
            });
            this.userState.businessAreaListValue = filteredBusinessArea;
            this.openSnackBar(res.message, true);
          },
          (err) => {
            this.openSnackBar(err.message, false);
            observer.error(err.error);
          }
        );
    });
  }

  getInterestedArea(): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getInterestedArea()
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            if (res && res.status && res.status === 'success' && res.data) {
              this.userState.interestedAreaListValue = res.data;
            } else {
              this.userState.interestedAreaListValue = [];
            }
          },
          (err) => {
            this.userState.interestedAreaListValue = [];
          }
        );
    });
  }

  createInterestedArea(params): Observable<any> {
    return new Observable((observer) => {
      this.prospektrApi.createInterestedArea(params).subscribe(
        (res) => {
          if (res && res.status && res.status === 'success') {
            observer.next(res);
          } else {
            observer.next({ message: 'Failed to add interested area.' });
          }
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  deleteInterestedArea(params): Observable<any> {
    return new Observable((observer) => {
      this.prospektrApi.deleteInterestedArea(params).subscribe(
        (res) => {
          observer.next(res);
          const interestedArea = (this.userState.interestedAreaListValue =
            this.userState.interestedAreaListValue.filter((element) => {
              return element.id !== params.id || element.name !== params.name;
            }));
          this.userState.interestedAreaListValue = interestedArea;
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  getClient() {
    this.userState.userClientGetInProgress = true;
    this.prospektrApi
      .getClient()
      .pipe(
        finalize(() => {
          this.userState.userClientGetInProgress = false;
        })
      )
      .subscribe((res) => {
        if (res && res.status && res.status === 'success' && res.data) {
          this.userState.userClient = res.data;
        }
      });
  }

  patchClient(clientId, patch): Observable<any> {
    return new Observable((observer) => {
      observer.next({ inProgress: true });
      this.prospektrApi
        .patchClient(clientId, patch)
        .pipe(
          finalize(() => {
            observer.next({ inProgress: false });
            observer.complete();
          })
        )
        .subscribe((res) => {
          if (res && res.status && res.status === 'success') {
            const userClient = this.userState.userClient;
            for (const key in patch) {
              if (Object.prototype.hasOwnProperty.call(patch, key)) {
                userClient[key] = patch[key];
              }
            }
            this.userState.userClient = userClient;
          }
        });
    });
  }

  getBrandInfo() {
    this.userState.userClientGetInProgress = true;
    this.prospektrApi
      .getBrandInfo()
      .pipe(
        finalize(() => {
          this.userState.userClientGetInProgress = false;
        })
      )
      .subscribe((res) => {
        if (res && res.status && res.status === 'OK' && res.data) {
          this.userState.brandInfo = res.data;
        }
      });
  }

  patchBrandInfo(userId, patch): Observable<any> {
    return new Observable((observer) => {
      observer.next({ inProgress: true });
      this.prospektrApi
        .patchBrandInfo(userId, patch)
        .pipe(
          finalize(() => {
            observer.next({ inProgress: false });
            observer.complete();
          })
        )
        .subscribe((res) => {
          if (res && res.status && res.status === 'OK') {
            const brandInfo = this.userState.brandInfo;
            for (const key in patch) {
              if (Object.prototype.hasOwnProperty.call(patch, key) && brandInfo?.branding) {
                brandInfo.branding[key] = patch[key];
              }
            }
            this.userState.brandInfo = brandInfo;
            this.openSnackBar(res.message, true);
            observer.next(res);
          }
        });
    });
  }
  verifyMls(params: any): Observable<any> {
    return this.http.post(`${environment.prospektrBackendBaseURL}verify-mls`, params);
  }
  signUpForAgentLoanOfficer(params: any, userType: string): Observable<any> {
    return this.http.post(`${environment.prospektrBackendBaseURL}signup/${userType}`, params).pipe(
      catchError((error) => {
        console.log('Post request rejected by interceptor:', error);
        return throwError(error);
      })
    );
  }

  signUpForAppraisar(params: any): Observable<any> {
    return this.http.post(`${environment.prospektrBackendBaseURL}notifyCompanyManager`, params).pipe(
      catchError((error) => {
        console.log('Post request rejected by interceptor:', error);
        return throwError(error);
      })
    );
  }

  getCount(): Observable<any> {
    return this.http.get(`${environment.prospektrBackendBaseURL}getValueReportsCount`);
  }

  getUserDetails(): Observable<any> {
    return new Observable((observer) => {
      this.appState.setAppLoadingState('verifyLogin', true);
      this.http
        .post<any>(environment.prospektrBackendBaseURL + 'validateLogin', null)
        .pipe(
          catchError(() => {
            return EMPTY;
          }),
          finalize(() => this.appState.setAppLoadingState('verifyLogin', false))
        )
        .subscribe(
          (res: any) => {
            if (res && 'user_profile' in res) {
              if (moment().isAfter(res.renewalDate, 'day')) {
                res.userRole = 'trial';
              } else {
                res.userRole = 'client';
              }
              this.userState.userDetails = res;

              this.userState.userLocation = res?.workPlace?.userLocation || '';
              observer.next({ status: true });
            } else {
              observer.next({ status: true, message: 'Error occurred' });
            }
          },
          (err) => {
            observer.next({
              status: true,
              message: err.error && err.error.Message ? err.error.Message : 'Error occurred',
            });
          }
        );
    });
  }

  getQuestionnaireResponse() {
    return new Observable((observer) => {
      this.prospektrApi.getQuestionnaireResponse().subscribe(
        (res) => {
          if (res.status && res.status == 'success' && res.message) {
            observer.next(res);
          }
        },
        (err) => {
          this.openSnackBar(err.error.message || 'Failed to get questionnaire response', 'snackbar-error');
          observer.error(err.error);
        }
      );
    });
  }
  getQuestions() {
    return new Observable((observer) => {
      this.QuestionsApi.getQuestions().subscribe(
        (res) => {
          if (res.status && res.status == 'success' && res.message) {
            observer.next(res);
          }
        },
        (err) => {
          this.openSnackBar(err.error.message || 'Failed to fetch questions', 'snackbar-error');
          observer.error(err.error);
        }
      );
    });
  }
  updateQuestionnaireResponse(params) {
    return new Observable((observer) => {
      this.QuestionsApi.updateQuestionireResponse(params).subscribe(
        (res) => {
          this.openSnackBar(res.message || 'updated successfully', 'snackbar-error');
          observer.next(res);
        },
        (err) => {
          console.log('error', err);
          observer.error(err);
        }
      );
    }).pipe(take(1));
  }

  updateCmaConfiguration(params): Observable<any> {
    return new Observable((observer) => {
      this.prospektrApi.updateCmaConfiguration(params).subscribe(
        (res) => {
          if (res && res.statusCode == 200 && res.data) {
            this.openSnackBar(res.message || 'updated successfully', 'snackbar-error');
            observer.next(res);
          } else {
            observer.next({ message: 'Failed to add interested area.' });
          }
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  getCmaConfiguration(): Observable<any> {
    return new Observable((observer) => {
      this.prospektrApi.getCmaConfiguration().subscribe(
        (res) => {
          if (res && res.data) {
            observer.next(res);
            this.userState.cmaConfigurationListValue = res.data;
          }
        },
        (err) => {
          this.openSnackBar(err.error.message || 'Failed to fetch questions', 'snackbar-error');
          observer.error(err.error);
        }
      );
    });
  }
}
